import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import { Row, Col, Button, Modal } from 'react-bootstrap';

import { useHttpClient } from '../../shared/http-hook';
import LoadingSpinner from '../LoadingSpinner';

import CustomModal from '../helpers/CustomModal';

const DashboardHotornot = () => {
    const { sendRequest } = useHttpClient();

    const [loading, setLoading] = useState(false);
    const [data, setData] = useState();
    const [liked, setLiked] = useState(0);
    const [disliked, setDisliked] = useState(0);
    const [clickedCount, setClickedCount] = useState(0);

    const loadProfile = async () => {
        setLoading(true)

        try {
            const response = await sendRequest('../data/users.json');
            const result = response.sort(function() { 
                return .5 - Math.random() 
            }).slice(0, 1);
            
            setData(result[0])

            setTimeout(function(){
                setLoading(false)
            }, 2000);
        } catch (err) {
            console.log(err);
        }
    }

    const likeProfile = () => {
        setLiked(liked + 1)
        setClickedCount(clickedCount + 1)

        let likedList = [];
        likedList = JSON.parse(localStorage.getItem('liked_list')) || [];
        likedList.push(data.nick);
        localStorage.setItem('liked_list', JSON.stringify(likedList));

        loadProfile()
    }

    const dislikeProfile = () => {
        setDisliked(disliked + 1)
        setClickedCount(clickedCount + 1)

        let dislikedList = [];
        dislikedList = JSON.parse(localStorage.getItem('disliked_list')) || [];
        dislikedList.push(data.nick);
        localStorage.setItem('disliked_list', JSON.stringify(dislikedList));

        loadProfile()
    }
 
    const playGame = () => {
        localStorage.setItem('honplayed', true);
        loadProfile();
    }

    useEffect(() => {
        playGame()
    }, []);

    //popover
    const [customBtn, setCustomBtn] = useState(false);
    const [customBtnType, setCustomBtnType] = useState();   

    useEffect(() => {
        var myArray = [1, 2, 3]; 
        var rand = myArray[(Math.random() * myArray.length) | 0]

        if(clickedCount === 3) {
            setCustomBtn(true)
            setCustomBtnType(rand)
            
            setClickedCount(0)
        }
    }, [clickedCount]);

    const aff_sub = localStorage.getItem('transaction_id')
    const aff_sub1 = localStorage.getItem('aff_sub1')
    const aff_sub2 = localStorage.getItem('aff_sub2')
    const aff_sub3 = localStorage.getItem('aff_sub3')
    const aff_sub4 = localStorage.getItem('aff_sub4')
    const aff_sub5 = localStorage.getItem('aff_sub5')

    const link = `?sub1=${aff_sub1}&sub2=${aff_sub2}&sub3=${aff_sub3}&sub4=${aff_sub4}&sub5=${aff_sub5}&transaction_id=${aff_sub}`;

    return (
        <>
            <CustomModal display_custom={customBtn} type={customBtnType} onClose={() => {
                setCustomBtn(false)
                setCustomBtnType(null)
            }} />   
            
            <div className="hon-game position-relative">

                <h1>Hot or not</h1>
                <p>Swipe game</p>

                <hr />

                <Row className="justify-content-md-center">
                    <Col xs lg="2">
                        <h3>{liked}</h3>
                        <p>Liked</p>
                    </Col>
                    <Col md="auto"></Col>
                    <Col xs lg="2">
                        <h3>{disliked}</h3>
                        <p>Disliked</p>
                    </Col>
                </Row>

                {!loading && !data && (
                    <>
                    <center>
                        <button className="hon-startgame" onClick={playGame}> <i className="fa-solid fa-play"></i> </button>
                    </center>
                    </>
                )}

                <div className="hon-loader">
                    {loading && <LoadingSpinner />}
                </div>

                {!loading && data && (
                    <Row>
                        <Col md={{ span: 6, offset: 3 }}>
                        <div className="hon-card position-relative">

                        <div className="hon-cardsbtns text-center">
                        <Button size="lg" variant="dark" className="m-2" onClick={dislikeProfile}> <i className="fa-solid fa-circle-minus"></i> </Button>
                        <Button size="lg" variant="danger" className="m-2" onClick={likeProfile}> <i className="fa-solid fa-heart"></i> </Button>
                        </div>

                        <div className="position-relative text-center">
                        <Link to={`/dashboard/member/${data.nick}`}>
                        <img src={data.image} className="img-fluid img-thumbnail" style={{ maxHeight: "300px" }} alt={data.nick} />
                        </Link>

                        <div className="hon-card-image-btns">
                        <a href={`https://www.gorillatrk1.com/3J67C/PGHSXQ/${link}`} target="_blank"> <Button variant="dark" size="sm">Go Live</Button> </a>
                        <a href={`https://track.gorillatracker.com/9f079394-ce46-4e4a-8082-6dacea26146b${link}`} target="_blank"> <Button variant="dark" size="sm">Let's Chat</Button> </a>
                        </div>

                        </div>
                        <Link to={`/dashboard/member/${data.nick}`}><h1>{data.nick}</h1></Link>
                        <p>{data.name}</p>
                        <p>{data.age} <small>years old</small></p>
                        </div>
                        </Col>
                    </Row>
                )}
            </div>
        </>
    )
}

export default DashboardHotornot;