import React, { useEffect, useState, useLayoutEffect } from 'react';
import { Link } from 'react-router-dom';

import { Form, Button, Badge } from 'react-bootstrap';
import LoadingSpinner from './LoadingSpinner';
import { useHttpClient } from '../shared/http-hook';

import { useSearchParams } from "react-router-dom";
import { hashString } from 'react-hash-string';

import axios from 'axios';

import { runEverflow } from './helpers/EFHelpers';
import { getMoarCount, addMoarCount, getAdamCount, addAdamCount } from './helpers/ApiHelpers';

const CreateAccount = props => {
    const { sendRequest } = useHttpClient();

    const [step, setStep] = useState(0);
    const [maxStep, setMaxStep] = useState(2)
    const [email, setEmail] = useState();
    const [years, setYears] = useState();
    const [error, setError] = useState();
    const [loading, setLoading] = useState(false);

    const [toastError, setToastError] = useState(false)

    const [userInfo, setUserInfo] = useState();

    const username = Math.random().toString(36).substr(2, 9)

    const getInfo = async () => {
        try {
            const response = await sendRequest('https://geolocation-db.com/json/');
            setUserInfo(response)
        } catch (err) {
            console.log(err)
        }
    }

    useEffect(() => {
        getInfo()
    }, []);

    function openInNewTab(href) {
        Object.assign(document.createElement('a'), {
          target: '_blank',
          rel: 'noopener noreferrer',
          href: href,
        }).click();
    }

    // global 
    function getRandomBirthdate(age) {
        const currentDate = new Date();
        const currentYear = currentDate.getFullYear();
        
        const birthYear = currentYear - age;
      
        const birthMonth = Math.floor(Math.random() * 12) + 1;
      
        const birthDay = Math.floor(Math.random() * 28) + 1;
      
        const formattedDate = `${birthMonth}/${birthDay}/${birthYear}`;
      
        return formattedDate;
    }
      
    const globalLead = async (url, email) => {
        const randomBirthdate = years ? getRandomBirthdate(years) : null;
  
        try {
            const response = await axios.get(`https://prestigeww.waypointsoftware.io/capture.php?xAuthentication=5feca4f1951112834804944ccbf5f3f4&email=${email}&PostURL=${url}&DOB=${randomBirthdate}`)
        } catch (err) {
            console.log(err)
        }
    }
  

    // Everflow Helpers
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);

    const ef = urlParams.get('efpid');
    const sub1 = urlParams.get('sub1');
    const ref = urlParams.get('ref')

    // aditional
    const createMailWizz = async () => {
        try {
          const response = await sendRequest(
            'https://gorillamediallc.com/onctesting/api/createmailwizz',
            'POST',
            JSON.stringify({
                username: username,
                email: email,
                years: years,
                country_code: userInfo ? userInfo.country_code : null,
                aff_sub2: aff_sub2,
                AFFID: aff_sub5
            }),
            {
              'Content-Type': 'application/json'
            }
          );            
          console.log(response)

        } catch (err){
            console.log(err);
        }
    }

    const createDa = async () => {
        try {
            const response = await sendRequest(
              'https://gorillamediallc.com/onctesting/api/createda',
              'POST',
              JSON.stringify({
                  username: username,
                  email: email,
                  ip: userInfo.IPv4,
                  aff: aff_sub1,
                  aff_sub2: aff_sub2
              }),
              {
                'Content-Type': 'application/json'
              }
            );            

            const formatdata = response?.data?.split("|");
            const url = formatdata[3];
            
            if(url && url.startsWith('https://')){
                window.location.href = 'https://onenightconfidential.com';
                openInNewTab(url)
            } else {
                await createFreeFlirtz()
            }
  
          } catch (err){
              console.log(err);
          }
    }

    const createAfmy = async () => {
        try {
            const response = await sendRequest(
              'https://gorillamediallc.com/onctesting/api/createafmy',
              'POST',
              JSON.stringify({
                  username: username,
                  email: email,
                  ip: userInfo.IPv4,
                  aff: aff_sub1,
                  aff_sub2: aff_sub2,
                  aff_sub3: aff_sub3,
                  aff_sub4: aff_sub4,
                  aff_sub5: aff_sub5,
                  transaction_id: transaction_id
              }),
              {
                'Content-Type': 'application/json'
              }
            );    

            if(response.data && response.data.url.startsWith('https://')){
                window.location.href = 'https://onenightconfidential.com';
                openInNewTab(response.data.url)
            } else {
                await createAdam()
            }
  
          } catch (err){
              console.log(err);
          }
    }

    const createAdam = async () => {
        try {
            const response = await sendRequest(
              'https://gorillamediallc.com/onctesting/api/createadam',
              'POST',
              JSON.stringify({
                  email: email,
                  ip: userInfo.IPv4,
                  aff: aff_sub1
              }),
              {
                'Content-Type': 'application/json'
              }
            );    
            
            const adamCount = await getAdamCount();

            if(response.data && response.data.successUrl.startsWith('https://') && adamCount < 50){
                window.location.href = 'https://onenightconfidential.com';
                openInNewTab(response.data.successUrl)

                await addAdamCount()
            } else {
                await createTrawle()
            }
  
          } catch (err){
              console.log(err);
          }
    }

    const createMirelia = async () => {
        try {
            const response = await sendRequest(
              'https://gorillamediallc.com/onctesting/api/createmirelia',
              'POST',
              JSON.stringify({
                  email: email,
                  ip: userInfo.IPv4,
                  aff: aff_sub1
              }),
              {
                'Content-Type': 'application/json'
              }
            );    
            
            if(response.responsedata) {
                const url = response.responsedata.url;
                const status = response.responsedata.status;

                if(url && url.startsWith('https://')){
                    window.location.href = 'https://onenightconfidential.com';
                    openInNewTab(url)

                    switch (status) {
                        case "success":
                          await axios.get(`https://gorillamediallc.com/onctesting/api/serveref/?offer=495&efpid=2&sub1=${aff_sub1}&ref=${aff_sub2}`);
                          break;
                        case "success_rec":
                          await axios.get(`https://gorillamediallc.com/onctesting/api/serveref/?offer=496&efpid=2&sub1=${aff_sub1}&ref=${aff_sub2}`);
                          break;
                        default:
                          return;
                    }
                      
                } else {
                    await createAfmy()
                }
            } else {
                await createAfmy()
            }
  
          } catch (err){
              console.log(err);
          }
    }

    const createTrawle = async () => {
        try {
            const response = await sendRequest(
              'https://gorillamediallc.com/onctesting/api/createtrawle',
              'POST',
              JSON.stringify({
                  username: username,
                  email: email,
                  ip: userInfo.IPv4,
                  aff: aff_sub1,
                  aff_sub2: aff_sub2
              }),
              {
                'Content-Type': 'application/json'
              }
            );          
            
            if(response.data && response.data.login_url.startsWith('https://')){
                window.location.href = 'https://onenightconfidential.com';
                openInNewTab(response.data.login_url)
            } else {
                setError('Email did not pass the cascade!')
                window.open('https://www.gorillatrk1.com/3J67C/CHD91P/', '_blank');
                return; 
            }

          } catch (err){
              console.log(err);
          }
    }

    const createFlirtRevenue = async () => {
        try {
            const response = await sendRequest(
              'https://gorillamediallc.com/onctesting/api/createflirtrevenue',
              'POST',
              JSON.stringify({
                  username: username,
                  email: email,
                  ip: userInfo.IPv4,
                  aff: aff_sub1,
                  aff_sub2: aff_sub2

              }),
              {
                'Content-Type': 'application/json'
              }
            );            
  
          } catch (err){
              console.log(err);
          }
    }

    function randomGenerator() {
        var text = "";
        var possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz";
      
        for (var i = 0; i < 10; i++)
          text += possible.charAt(Math.floor(Math.random() * possible.length));
      
        return text.toLowerCase();
    }

    var randomstring = randomGenerator();
    var randomusername = randomGenerator();
    // const createPure = async () => {
    //     try {
    //         const response = await sendRequest(
    //             'https://gorillamediallc.com/onctesting/api/createpure',
    //             'POST',
    //             JSON.stringify({
    //                 uname: randomusername,
    //                 email: email,
    //                 password: randomstring,
    //                 ip_address: userInfo.IPv4,
    //                 city: userInfo.city,
    //                 state: userInfo.state,
    //                 aff: aff_sub1,
    //                 aff_sub2: aff_sub2
    //             }),
    //             {
    //               'Content-Type': 'application/json'
    //             }
    //           );          
              
    //           if(response.data !== "\"OK\""){
    //             createMirelia()
    //           }
    //     } catch (err) {
    //         console.log(err)
    //     }
    // }

    // const createFlirt = async () => {
    //     try {
    //         const response = await sendRequest(
    //           'https://gorillamediallc.com/onctesting/api/createflirt',
    //           'POST',
    //           JSON.stringify({
    //               email: email,
    //               ip: userInfo.IPv4
    //           }),
    //           {
    //             'Content-Type': 'application/json'
    //           }
    //         );            
            
    //         console.log(response)
  
    //       } catch (err){
    //           console.log(err);
    //       }       
    // }

    /*const createTm = async () => {
        try {
            const response = await sendRequest(
              'https://gorillamediallc.com/onctesting/api/createtm',
              'POST',
              JSON.stringify({
                  username: username,
                  email: email,
                  ip: userInfo.IPv4
              }),
              {
                'Content-Type': 'application/json'
              }
            );            
            
            let stringify = JSON.stringify(response)
            let finall = JSON.parse(stringify)
            
            if(finall.callstatus === true){
                alert('success trawle open tab')
                return;
            } else {
                createTrawle()
            }
  
          } catch (err){
              console.log(err);
          }
    }
    */    

    const createFlirtingTok = async () => {
        try {
            const response = await sendRequest(
              'https://gorillamediallc.com/onctesting/api/createflirtingtok',
              'POST',
              JSON.stringify({
                  username: username,
                  email: email,
                  ip: userInfo.IPv4,
                  aff: aff_sub1,
                  aff_sub2: aff_sub2

              }),
              {
                'Content-Type': 'application/json'
              }
            );    
                        
            if(response.data && response.data.redirectUrl.startsWith('https://')){
                window.location.href = 'https://onenightconfidential.com';
                openInNewTab(response.data.redirectUrl)
            } else {
                await createMoar()
            }
  
          } catch (err){
              console.log(err);
          }
    }

    const createGrandHookup = async () => {
        try {
            const response = await sendRequest(
              'https://gorillamediallc.com/onctesting/api/creategrandhookup',
              'POST',
              JSON.stringify({
                  username: username,
                  email: email,
                  ip: userInfo.IPv4,
                  aff: aff_sub1,
                  aff_sub2: aff_sub2

              }),
              {
                'Content-Type': 'application/json'
              }
            );    
                        

            if(response.data && response.data.redirectUrl.startsWith('https://')){
                window.location.href = 'https://onenightconfidential.com';
                openInNewTab(response.data.redirectUrl)
            } else {
                await createFlirtingTok()
            }
  
          } catch (err){
              console.log(err);
          }
    }

    const createMoar = async () => {
        try {
            const response = await sendRequest(
              'https://gorillamediallc.com/onctesting/api/createmoar',
              'POST',
              JSON.stringify({
                  username: username,
                  email: email,
                  ip: userInfo.IPv4,
                  aff: aff_sub1,
                  aff_sub2: aff_sub2

              }),
              {
                'Content-Type': 'application/json'
              }
            );    

            const moarCount = await getMoarCount();
                        
            if(response.data && response.data.data.auto_login.startsWith('https://') && moarCount < 150){
                // await axios.get(`https://gorillamediallc.com/onctesting/api/serveref/?offer=494&efpid=2&sub1=${aff_sub1}&ref=${aff_sub2}`)

                window.location.href = 'https://onenightconfidential.com';
                openInNewTab(response.data.data.auto_login)

                await addMoarCount()
            } else {
                await createDa()
            }
  
          } catch (err){
              console.log(err);
          }
    }

    // const createSniper = async () => {
    //     try {
    //         const response = await sendRequest(
    //           'https://gorillamediallc.com/onctesting/api/createsniper',
    //           'POST',
    //           JSON.stringify({
    //               username: username,
    //               email: email,
    //               ip: userInfo.IPv4,
    //               aff: aff_sub1,
    //               aff_sub2: aff_sub2

    //           }),
    //           {
    //             'Content-Type': 'application/json'
    //           }
    //         );    

    //         console.log(response.data)    
    //         // const url = response.data.data.auto_login;

    //         // if(response.data && response.data.data.auto_login.startsWith('https://')){
    //         //     window.location.href = 'https://onenightconfidential.com';
    //         //     openInNewTab(url)
    //         // } else {
    //         //     createFlirtingTok()
    //         // }
  
    //       } catch (err){
    //           console.log(err);
    //       }
    // }

    const createFreeFlirtz = async () => {
        try {
            const response = await sendRequest(
              'https://gorillamediallc.com/onctesting/api/createfreeflirtz',
              'POST',
              JSON.stringify({
                  username: username,
                  email: email,
                  ip: userInfo.IPv4,
                  aff: aff_sub1,
                  aff_sub2: aff_sub2

              }),
              {
                'Content-Type': 'application/json'
              }
            );    

            if(response.data){
                window.location.href = 'https://onenightconfidential.com';

                const url = extractAutoLoginURL(response.data);  
                openInNewTab(url)
            } else {
                await createHornySwipe()
            }
  
          } catch (err){
              console.log(err);
          }
    }

    const createHornySwipe = async () => {
        try {
            const response = await sendRequest(
              'https://gorillamediallc.com/onctesting/api/createhornyswipe',
              'POST',
              JSON.stringify({
                  username: username,
                  email: email,
                  ip: userInfo.IPv4,
                  aff: aff_sub1,
                  aff_sub2: aff_sub2

              }),
              {
                'Content-Type': 'application/json'
              }
            );    

            if(response.data){
                window.location.href = 'https://onenightconfidential.com';

                const url = extractAutoLoginURL(response.data);  
                openInNewTab(url)
            } else {
                await createNaughtySwipe()
            }
  
          } catch (err){
              console.log(err);
          }
    }

    const createNaughtySwipe = async () => {
        try {
            const response = await sendRequest(
              'https://gorillamediallc.com/onctesting/api/createnaughtyswipe',
              'POST',
              JSON.stringify({
                  username: username,
                  email: email,
                  ip: userInfo.IPv4,
                  aff: aff_sub1,
                  aff_sub2: aff_sub2

              }),
              {
                'Content-Type': 'application/json'
              }
            );    

            if(response.data){
                window.location.href = 'https://onenightconfidential.com';

                const url = extractAutoLoginURL(response.data);  
                openInNewTab(url)
            } else {
                await createMirelia()
            }
  
          } catch (err){
              console.log(err);
          }
    }

    const [searchParams, setSearchParams] = useSearchParams();
    const transaction_id = searchParams.get("aff_sub");
    const aff_sub1 = searchParams.get("aff_sub1");
    const aff_sub2 = searchParams.get("aff_sub2");
    const aff_sub3 = searchParams.get("aff_sub3");
    const aff_sub4 = searchParams.get("aff_sub4");
    const aff_sub5 = searchParams.get("aff_sub5");
    
    const saveFileUsers = async (data) => {
        try {
          await axios.post(
            'https://gorillamediallc.com/onctesting/api/savefileusers',
            JSON.stringify(data),
            {
              headers: {
                'Content-Type': 'application/json'
              }
            }
          );
      
        } catch (err) {}
      };

      const [usersList, setUsersList] = useState([])
      const fetchUsers = async () => {
        try {
          const response = await axios.get('https://gorillamediallc.com/onctesting/stored/data/usersdb.json', {
            headers: {
              'Content-Type': 'application/json'
            }
          });

          setUsersList(response.data)
        } catch (err) {
          console.log(err)
        }
      }
      useLayoutEffect(() => {
        fetchUsers();
      }, []);
      
      const checkDB = async (email) => {
        let checked;

        try {
          const result = usersList.find((user) => user.email === email);
      
          if (result) {
            checked = true;
          } else {
            checked = false;
      
            const formObject = {
              email: email
            };
      
            usersList.push(formObject)
          }
      
        } catch (err) {
          console.log(err);
        }

        return checked;
    };      

    const emailAPI = async (email) => {
        try {
            const response = await sendRequest(
              'https://gorillamediallc.com/onctesting/api/checkemailapi',
              'POST',
              JSON.stringify({
                  email: email
              }),
              {
                'Content-Type': 'application/json'
              }
            );


            return response.dataresponse;      
          } catch (err) {
              console.log(err)
          }
    }

    const submitForm = async () => {

        if(step !== maxStep){
            setError('You must finish all steps!')
            return;          
        }

        setLoading(true)

        try {
            const user = {
                username: username,
                email: email,
                years: years
            }

            localStorage.setItem('user', JSON.stringify(user));
            props.onCreate(user);
            setStep('finished')

            // postback
            if(transaction_id){
                const hash = hashString(email);
                localStorage.setItem('transaction_id', transaction_id)
                await axios.get(`https://www.gorillatrk1.com/?nid=1737&transaction_id=${transaction_id}&adv1=${hash}&sub2=${aff_sub2}&sub3=${aff_sub3}`)    
            }

            localStorage.setItem('aff_sub1', aff_sub1)
            localStorage.setItem('aff_sub2', aff_sub2)
            localStorage.setItem('aff_sub3', aff_sub3)
            localStorage.setItem('aff_sub4', aff_sub4)
            localStorage.setItem('aff_sub5', aff_sub5)

            // global lead
            await saveFileUsers(usersList)

            await globalLead(window.location.hostname, email)
            await createFlirtRevenue()

            await createMoar()      
            await createMailWizz()      
        } catch (err) {
            setError(err)
        } finally {
            setTimeout(() => {
                window.location.href = "/"
            }, 5000);
        }
    }

    function checkEmail(str) {
        var pattern = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
        return pattern.test(str);
    }

    const continueStep = async (e) => {
        e.preventDefault();

        setToastError(null)
        
        if((step === 1 && !years) || (step === 1 && years < 18)){
            setError('Age are required! Must be older than 18 years!')
            return;      
        } 
        // step email
        if(step === 1 && checkEmail(email) === false){
                setError('Email is not valid!')
                return;      
        } 

        if(step === 1 && email){
            const emailapi = await emailAPI(email);

            if(emailapi && (emailapi.valid === false || emailapi.recent_abuse === true || emailapi.deliverability === "low")){
                setError('Email did not pass the request!')
                window.open('https://www.gorillatrk1.com/cmp/2D2DH/RF39C/', '_blank');
                return; 
            }
        }

        const emailDB = await checkDB(email);
        if(step === 1 && emailDB === true){
            setError('Email is already in use!')
            return;             
        }

        if(step < maxStep || !error){
            setLoading(true)

            setTimeout(function(){
                    setStep(step + 1);
                    setError('')
                    setLoading(false)
            }, 2000);
        }

    }

    useEffect(() => {
        
        if(step === 2) {
            setLoading(true)
    
            setTimeout(() => {
                submitForm()
            }, 2000);
        }

    }, [step]);

    function extractAutoLoginURL(responseData) {
        const regex = /auto=([^&]+)/;
        const match = responseData.match(regex);
        
        if (match && match[1]) {
          const encodedURL = match[1];
          const decodedURL = decodeURIComponent(encodedURL);
          return decodedURL;
        } else {
          return null;
        }
    }
    
    return (
        <React.Fragment>

            {step !== 'finished' ? (
            <center>
            <Badge bg="dark">
                <span>{step}</span> / <span>{maxStep}</span>
            </Badge>
            </center>
            ) : null}

            <Form onSubmit={submitForm}>
            {loading && <LoadingSpinner asOverlay />}
            {step === 0 ? (
                <>
                <div className="form-step">
                    <h2>Welcome to One Night Confidential!</h2> <hr />
                    
                    <div className="from-step-welcome">
                    <h3> the easiest way to <b>hook up</b> </h3>   
                    <p>Hurry up! 3 free subscription left</p>
                    <small>By proceeding I certify I am 18 years or older, agree to the <a href="/tos" target="_blank">TOS</a>, <a href="/privacypolicy" target="_blank">Privacy Policy</a> , sign up for newsletters and agree to receive messages.</small>     
                    </div>                        
                </div>
                </>
            ) : step === 1 ? (
                <>
                <div className="form-step">
                    <h2>Welcome to One Night Confidential!</h2> <hr />
                    
                    <Form.Group className="mb-3" controlId="formBasicAge">
                        <Form.Label>Age:</Form.Label>
                        <Form.Control type="text" name="age" maxLength="2" autoComplete="off" value={years ? years : ''} placeholder="Enter age" onChange={(e) => setYears(parseInt(e.target.value))} />
                    </Form.Group>                                  

                    <Form.Group className="mb-3" controlId="email_input">
                        <Form.Label>Email:</Form.Label>
                        <Form.Control type="email" name="email" autoComplete="off" value={email ? email : ''} placeholder="Enter email" onChange={(e) => setEmail(e.target.value)} />
                    </Form.Group>   
                    
                </div> 
                </>
            ) : step === 2 ? (
                <>
                  <div className="form-step mb-2">
                    <h2>FINISHED</h2> <hr />

                    <div className='finished-form'>
                        <h1><i className="fa-solid fa-clipboard-check"></i></h1>
                        <p>Profile created successfully!</p>
                    </div> 

                </div> 
                </>    
            ) : null}


            {error ? (
                        <>
                        <div className="ctm-error"> <i className="fa-solid fa-triangle-exclamation"></i> {error} </div>
                        </>
            ) : ''}
                            
            {
                typeof step === 'number' ? (
                    <>
                    {step === maxStep && typeof step == 'number' && step ? '' : <Button type="button" variant="dark" size="lg" className="form-btnright" onClick={continueStep}> {step === 0 ? 'Get Started' : 'Join for free'}  </Button> }            
                    </>
                ) : ''
            }
            </Form>
        </React.Fragment>
    )
}

export default CreateAccount;