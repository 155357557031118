import React, { useState, useEffect, useLayoutEffect } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

import { useAuth } from './shared/auth';
import { AuthContext } from './shared/auth-context';

import Welcome from './components/Welcome';
import Landing from './components/Landing';
import Dashboard from './components/Dashboard';
import Tos from './components/Tos';
import PrivacyPolicy from './components/PrivacyPolicy';
import Panel from './components/Panel';
import Model from './components/Model';

import IframeModal from './components/helpers/IframeModal';

import axios from 'axios';

function App() {
  const getUser = localStorage.getItem('user')
  const { email, username, logout } = useAuth();

  let routes;

  if(!email && !username) {

    routes = (
      <Routes>
        <Route path="/" element={<Landing />} />
        <Route path="*" element={<Landing />} />
        <Route path="/member/:nick" element={<Model />} />
		    <Route path="/welcome" element={<Welcome />} />
        <Route path="/tos" element={<Tos />} />
        <Route path="/privacypolicy" element={<PrivacyPolicy />} />
        <Route path="/panel" element={<Panel />} />
      </Routes>
    )

  } else {

    routes = (
      <Routes>
        <Route path="/*" element={<Dashboard />} />
        <Route path="*" element={<Dashboard />} />
        <Route path="/tos" element={<Tos />} />
        <Route path="/privacypolicy" element={<PrivacyPolicy />} />
        <Route path="/panel" element={<Panel />} />
    </Routes>
    )

  }

  
  const [globalIframe, setGlobalIframe] = useState(false);

  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const upgrade = urlParams.get('upgrade')

  useEffect(() => {
    if(upgrade === "true") setGlobalIframe(true)
  }, [upgrade]);

    // postback
    const postback = urlParams.get('postback');
  
    const efImport = async (url, id) => {
        await axios.post('https://gorillamediallc.com/onctesting/api/efimport', {
          url: url,
          id: id
        })
        .then((res) => {
          if(res.data.message === "success"){
            console.log('success last')
          }
        })
        .catch((err) => {
          console.log("AXIOS ERROR: ", err);
        })
    }
  
    const efUpload = async (url, id) => {
        await axios.post('https://gorillamediallc.com/onctesting/api/efupload', {
          url: url,
          id: id
        })
        .then((res) => {
          if(res.data.message === "success"){
            console.log('uploaded front')
            efImport(res.data.url, res.data.id)
          }
        })
        .catch((err) => {
          console.log("AXIOS ERROR: ", err);
        })
    }
  
    const runEverflow = async () => {
          const ef = urlParams.get('efpid');
          const sub1 = urlParams.get('sub1');
          const ref = urlParams.get('ref')
  
          await axios.post('https://gorillamediallc.com/onctesting/api/efconvert', {
            ef: ef,
            sub1: sub1,
            ref: ref
          })
          .then((res) => {
            if(res.data.message === "success"){
              efUpload(res.data.location, res.data.id)
            }
          })
          .catch((err) => {
            console.log("AXIOS ERROR: ", err);
          })
    }
  
    useEffect(() => {
      if(postback === "on"){
        runEverflow()
      }
    }, []);

    // cplsystem
    const cplsystem = urlParams.get("cplsystem");

    useLayoutEffect(() => {
        if(cplsystem === "on") {
          localStorage.setItem('cplsystem', 'on')

          localStorage.setItem('uid', urlParams.get('uid'))
          localStorage.setItem('transaction_id', urlParams.get('aff_sub'))
          localStorage.setItem('aff_sub1', urlParams.get('aff_sub1'))
          localStorage.setItem('aff_sub2', urlParams.get('aff_sub2'))
          localStorage.setItem('aff_sub3', urlParams.get('aff_sub3'))
          localStorage.setItem('aff_sub4', urlParams.get('aff_sub4'))
          localStorage.setItem('aff_sub5', urlParams.get('aff_sub5'))
        }
    }, [cplsystem]);

    const transaction_id = urlParams.get("aff_sub") || localStorage.getItem('transaction_id')

    const uid = urlParams.get("uid") || localStorage.getItem('uid')

    const local_aff_sub1 = urlParams.get("aff_sub1") || localStorage.getItem('aff_sub1')
    const local_aff_sub2 = urlParams.get("aff_sub2") || localStorage.getItem('aff_sub2')
    const local_aff_sub3 = urlParams.get("aff_sub3") || localStorage.getItem('aff_sub3')
    const local_aff_sub4 = urlParams.get("aff_sub4") || localStorage.getItem('aff_sub4')
    const local_aff_sub5 = urlParams.get("aff_sub5") || localStorage.getItem('aff_sub5')

    const customlink = `?sub1=${local_aff_sub1}&sub2=${local_aff_sub2}&sub3=${local_aff_sub3}&sub4=${local_aff_sub4}&sub5=${local_aff_sub5}&uid=${uid}`;

    const [clicks, setClicks] = useState(0);

    const handleClick = (event) => {
      event.stopPropagation();

      setClicks(prev => prev + 1);
    };

    const handleOpenOnetimeLink = () => {
      const parseUser = getUser && JSON.parse(getUser)
      const dynamicCPL = localStorage.getItem('cplsystem')
      let onetimelink;
      if(parseUser && parseUser.years > 30) {
        onetimelink = `https://www.gorillatrk1.com/3J67C/XPH5N8/${customlink}`
      } else if(dynamicCPL === 'on') {
        onetimelink = `https://track.gorillatracker.com/4f7872ee-f764-4b10-b04d-e711d6c5af52?sub1=${local_aff_sub1}&sub2=${local_aff_sub2}&sub3=${local_aff_sub3}&sub4=${local_aff_sub4}&sub5=${local_aff_sub5}&transaction_id=${transaction_id}`;
      } else {
        onetimelink = `https://www.gorillatrk1.com/3J67C/XN48XM/${customlink}`
      }

      window.open(`${window.location.href}?ots=on`, '_blank');
      window.location.href = onetimelink
    }

    const localOts = localStorage.getItem('ots')
    const ots = urlParams.get('ots');

    useEffect(() => {
      if (clicks === 1 && !ots && !localOts) {
        handleOpenOnetimeLink();
        localStorage.setItem('ots', 'on')
      }
    }, [clicks, ots]);

    useEffect(() => {
      document.addEventListener('click', handleClick);

      return () => {
        document.removeEventListener('click', handleClick);
      };
    }, []);


    const skiplogin = urlParams.get('skiplogin')
    const createUser = () => {
        const generateRandomUsername = () => {
            const adjectives = ['Happy', 'Sunny', 'Lucky', 'Creative', 'Adventurous'];
            const nouns = ['Explorer', 'Dreamer', 'Champion', 'Pioneer', 'Traveler'];
            const randomAdjective = adjectives[Math.floor(Math.random() * adjectives.length)];
            const randomNoun = nouns[Math.floor(Math.random() * nouns.length)];
            return `${randomAdjective}${randomNoun}`;
        };

        const generateRandomEmail = () => {
            const domains = ['gmail.com', 'yahoo.com', 'outlook.com', 'example.com'];
            const randomUsername = generateRandomUsername().toLowerCase();
            const randomDomain = domains[Math.floor(Math.random() * domains.length)];
            return `${randomUsername}@${randomDomain}`;
        };

        const generateRandomYears = () => Math.floor(Math.random() * 50) + 18;

        const user = {
            username: generateRandomUsername(),
            email: generateRandomEmail(),
            years: generateRandomYears()
        };

        return user;
    };

    useLayoutEffect(() => {
      if(skiplogin === 'on'){
        const randomUser = createUser();
        localStorage.setItem('user', JSON.stringify(randomUser));
      }
    }, [skiplogin]);

    // AFF SUB2 FROM URL TO TOKEN1 ( PUSH )
    const urlParamsAff = new URLSearchParams(window.location.search);
    const urlAffSub2 = urlParamsAff.get("aff_sub2");
    
    useEffect(() => {
      if (urlAffSub2) {
        const updatedUrlParams = new URLSearchParams(urlParamsAff);
        updatedUrlParams.set('token1', urlAffSub2);
    
        const newUrl = `${window.location.pathname}?${updatedUrlParams.toString()}`;
        window.history.replaceState({}, '', newUrl);
      }
    }, [urlAffSub2, urlParamsAff]);

  return (
  <AuthContext.Provider
      value={{
        email: email,
        username: username,
        logout: logout
      }}
    >

    <Router>
        <IframeModal display={globalIframe} background={false} />

        {routes}
    </Router>
    </AuthContext.Provider>
  );
}

export default App;
