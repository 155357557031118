import React, { useState, useEffect } from 'react';

import { useHttpClient } from '../../shared/http-hook';
import { Link } from 'react-router-dom';

import DashboardStartMessage from './DashboardStartMessage';
import { Row, Col, Button } from 'react-bootstrap';

import IframeModal from '../helpers/IframeModal';

const DashboardMatches = () => {
    const local = JSON.parse(localStorage.getItem('user'))
    const { sendRequest } = useHttpClient();
    const [data, setData] = useState()

    const getMatches = async () => {
        try {
            const response = await sendRequest('https://gorillamediallc.com/onctesting/api/getusersitems');
            const filter = response.user_items.filter((item) => item.type === "matches" && item.member === local.username)
            setData(filter.reverse())
        } catch (err) {}
    }

    useEffect(() => {
        getMatches()

        const interval = setInterval(() => {
            if(localStorage.getItem('honplayed') === "true"){
                getMatches()
            }

        }, 10000);
        return () => clearInterval(interval);       
    }, []);

    
    const [iframeBtn, setIframeBtn] = useState(false);
    const [customFullscreen, setCustomFullscreen] = useState(false);  

    return (
        <>
        <IframeModal display={iframeBtn} custom_fullscreen={customFullscreen} onClose={() => {
            setIframeBtn(false)
			setCustomFullscreen(false)
            localStorage.setItem('iframeactive', false)
        }} />

        {localStorage.getItem('honplayed') === "true" ? (
            <>
            <div className="dashboard-right addbordercontent position-relative">

            <h3 className="db-title"><i className="fa-solid fa-infinity"></i> My matches</h3> <hr />

            {data && data.length < 1 && (
                <>
                <p>No results!</p>
                </>
            )}

            {data && data.length > 10 && (
                <>
                        <div className="blured-message">
                                You've reached your limit - verify your age to unlock all messages!
                                <center>
                                <Button variant="dark btn-sm m-2" onClick={() => {
                                        setIframeBtn(true)
                                        setCustomFullscreen(true)
                                        localStorage.setItem('iframeactive', true)
                                }}>
                                Verify now
                               </Button>
                                </center>
                        </div>
                </>
            )}

            {data && data.map((item, index) => {
                let blured;
                if(index > 10){
                    blured = "blured-row";
                } else {
                    blured = "";
                }

                return (
                    <React.Fragment key={index}>
                    <div className={`profile-infoblock position-relative ` + blured}>
                            <Link to={!blured ? `/dashboard/member/${item.content.nick}` : ''}>
                                <Row>
                                    <Col sm={2}>
                                        <center>
                                        <img src={item.content.image} className="img-fluid" alt={item.content.nick} style={{ maxHeight: "50px" }} />
                                        </center>
                                    </Col>
                                    <Col sm={7}>
                                        <div className="profile-infoblockcontent">
                                                <p>{item.content.nick}</p>
                                                <span>Matched with your profile.</span>
                                                <small>{}</small>
                                        </div>
                                    </Col>
                                    <Col sm={3}>
                                        <Link to={`/dashboard/member/${item.content.nick}`}>
                                            <Button variant="dark" size="sm" className="w-100">View Profile</Button>
                                        </Link>

                                        <Link to="#" onClick={() => {
                                        setIframeBtn(true)
                                        setCustomFullscreen(true)
                                        localStorage.setItem('iframeactive', true)
                                        }}>
                                            <Button variant="dark" size="sm" className="w-100">Chat Now</Button>
                                        </Link>
                                    </Col>
                                </Row>
                            </Link>    
                    </div>
                    </React.Fragment>
                )
            })}

            </div>     
            </>      
        ) : (
            <>
            <DashboardStartMessage /> 
            </>
        )}
        </>
    )
}

export default DashboardMatches;