import React, { useState, useEffect, useCallback } from 'react';

import { Container, Form, Button, Modal, Row, Col, Tabs, Tab, Table, Badge } from 'react-bootstrap';
import LoadingSpinner from './LoadingSpinner'
import { useHttpClient } from '../shared/http-hook';

import Select from 'react-select'
import makeAnimated from 'react-select/animated';

import axios from 'axios';
import moment from 'moment';

const Panel = () => {
    const { isLoading, error, sendRequest, clearError } = useHttpClient();

    const [password, setPassword] = useState();
    const [accesed, setAccesed] = useState(false)

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [showIframe, setShowIframe] = useState(false);
    const handleCloseIframe = () => setShowIframe(false);
    const handleShowIframe = () => setShowIframe(true);

    const [showCustom, setShowCustom] = useState(false);
    const handleCloseCustom = () => setShowCustom(false);
    const handleShowCustom = () => setShowCustom(true);

    const [showWeb, setShowWeb] = useState(false);
    const handleCloseWeb = () => setShowWeb(false);
    const handleShowWeb = () => setShowWeb(true);

    const submitForm = async (e) => {
        e.preventDefault();

        try {
            const responseData = await sendRequest(
                'https://gorillamediallc.com/onctesting/api/panelauth',
                'POST',
                JSON.stringify({
                    password: password
                }),
                {
                  Authorization: 'Auth ' + password,
                  'Content-Type': 'application/json'
                }
            );  

            setAccesed(responseData.response)
            alert(responseData.message)
            if(responseData.response === true) localStorage.setItem('password', password)
        } catch (err) {
            console.log(error)
        }
    }

    const [image, setImage] = useState();
    const [redirection, setRedirection] = useState();
    const [redirectionAff, setRedirectionAff] = useState();
    const [adServer, setAdServer] = useState();
    const [split, setSplit] = useState();
    const [ageSystem, setAgeSystem] = useState();
    const [cplSystem, setCplSystem] = useState();
    const [ageLink, setAgeLink] = useState();
    const [emailSystem, setEmailSystem] = useState()
    const [cplLink, setCplLink] = useState()
    const [emailLink, setEmailLink] = useState();
    const [emailLinkOlder, setEmailLinkOlder] = useState();
    const [type, setType] = useState();

    const [ads, setAds] = useState([]);

    const getAds = async () => {
        try {
          const response = await sendRequest(
            'https://gorillamediallc.com/onctesting/stored/data/ads_list.json',
            'GET',
            null,
            {
              'Content-Type': 'application/json'
            }        
          )
    
          setAds(response)
        } catch (err) {
          console.log(err)
        }
    }
    
    const saveFile = async (data) => {
        try {
          await sendRequest(
            'https://gorillamediallc.com/onctesting/api/savefile',
            'POST',
            JSON.stringify(data),
            {
                Authorization: 'Auth ' + localStorage.getItem('password'),
                'Content-Type': 'application/json'
            }
          );
    
          getAds()
        } catch (err) {}
    }

    const saveFileWeb = async (data) => {
        try {
          await sendRequest(
            'https://gorillamediallc.com/onctesting/api/savefileweb',
            'POST',
            JSON.stringify(data),
            {
                Authorization: 'Auth ' + localStorage.getItem('password'),
                'Content-Type': 'application/json'
            }
          );
    
          getWebAds()
        } catch (err) {}
    }

    const saveFileIframes = async (data) => {
        try {
          await sendRequest(
            'https://gorillamediallc.com/onctesting/api/savefileiframes',
            'POST',
            JSON.stringify(data),
            {
                Authorization: 'Auth ' + localStorage.getItem('password'),
                'Content-Type': 'application/json'
            }
          );
    
          getIframes()
        } catch (err) {}
    }

    const saveFileCustom = async (data) => {
        try {
          await sendRequest(
            'https://gorillamediallc.com/onctesting/api/savefilecustom',
            'POST',
            JSON.stringify(data),
            {
                Authorization: 'Auth ' + localStorage.getItem('password'),
                'Content-Type': 'application/json'
            }
          );
    
          getIframes()
        } catch (err) {}
    }

    const saveFileTags = async (data) => {
        try {
          await sendRequest(
            'https://gorillamediallc.com/onctesting/api/savefiletags',
            'POST',
            JSON.stringify(data),
            {
                Authorization: 'Auth ' + localStorage.getItem('password'),
                'Content-Type': 'application/json'
            }
          );
    
          fetchTags()
        } catch (err) {}
    }

    // countries update
    const [storeCountries, setStoreCountries] = useState([]);
    const [countryCode, setCountryCode] = useState();
    const [countryID, setCountryID] = useState();
    const [countryLink, setCountryLink] = useState()

    const addCountries = () => {
        const countryObj = {
            gid: Math.floor(Math.random() * 9000000000) + 1000000000,
            code: countryCode,
            id: countryID,
            link: countryLink
        };
        
        if(!countryCode || !countryID || !countryLink){
            alert('All fields are required!')
            return;
        }

        setStoreCountries((prev) => [...prev, countryObj])
        setCountryCode('')
        setCountryID('')
        setCountryLink('')
    }

    const deleteAdCountry = (gid) => {
        const filter = storeCountries.filter((sc) => sc.gid !== gid)
        setStoreCountries(filter)
    }


    const addAds = async (e) => {
        e.preventDefault();

        if(!redirection || !type) {
            alert('All fields are required to add ad!')
            return;
        }

        const formObject = {
            id: Math.floor(Math.random() * 999999) + 1,
            image: image,
            redirection: redirection,
            redirection_aff: redirectionAff,
            adserver: adServer,
            split: split,
            agesystem: ageSystem,
            cplsystem: cplSystem,
            cpllink: cplLink,
            agelink: ageLink,
            emailsystem: emailSystem,
            emaillink: emailLink,
            emaillinkolder: emailLinkOlder,
            type: parseInt(type),
            countries: storeCountries

        }

        ads.push(formObject)
        saveFile(ads)

        handleClose()
    }

    const getPosition = (type) => {
        switch(type) {
            case 1:
                return "Top side";
            case 2:
                return "Left side";
            case 3:
                return "Right side";
            case 4:
                return "Bottom side";
            case 5:
                return "Popover";
            case 6:
                return "Slide-in chat notification";
            case 7:
                return "Intro Popover";
            case 8: 
                return "Fuck Now";
            default:
                return;
        }
    }


    const getPositionIframe = (type) => {
        switch(type) {
            case 1:
                return "Global";
            case 2:
                return "Normal";
            default:
                return;
        }
    }

    const getPositionCustom = (type) => {
        switch(type) {
            case 1:
                return "Chat notification";
            case 2:
                return "Native ads and view profile buttons";
            case 3:
                return "Send message";
            default:
                return;
        }      
    }

    const deleteAd = (id) => {
        const response = ads.filter((ad) => ad.id !== id);

        setAds(response)
        saveFile(response)
    }

    const [showSettings, setShowSettings] = useState(false);
    const handleCloseSettings = () => setShowSettings(false);
    const handleShowSettings = () => {
        fetchSettings()
        setShowSettings(true);
    }

    const [top, setTop] = useState();
    const [left, setLeft] = useState();
    const [right, setRight] = useState();
    const [bottom, setBottom] = useState();
    const [popover, setPopover] = useState();

    const saveSettings = async () => {
        try {
            const responseData = await sendRequest(
                'https://gorillamediallc.com/onctesting/api/panelsettingsset',
                'POST',
                JSON.stringify({
                    top: top,
                    left: left,
                    right: right,
                    bottom: bottom,
                    popover: popover
                }),
                {
                    Authorization: 'Auth ' + localStorage.getItem('password'),
                    'Content-Type': 'application/json'
                }
            );  
            
            if(responseData.message === "success") handleCloseSettings()
        } catch (err) {
            console.log(err)
        }
    }

    const fetchSettings = async () => {
        try {
            const response = await sendRequest(
                'https://gorillamediallc.com/onctesting/api/panelsettingsget',
                'GET',
                null,
                {
                    Authorization: 'Auth ' + localStorage.getItem('password'),
                    'Content-Type': 'application/json'
                }        
            )  
            
            setTop(response.maxtop)
            setLeft(response.maxleft)
            setRight(response.maxright)
            setBottom(response.maxbottom)
            setPopover(response.maxpopover)
        } catch (err) {
            console.log(err)
        }
    }

    const [showEdit, setShowEdit] = useState(false);
    const handleCloseEdit = () => setShowEdit(false);
    const handleShowEdit = () => setShowEdit(true);

    const [editDataId, setEditDataId] = useState();
    const [editDataImage, setEditDataImage] = useState();
    const [editDataRedirection, setEditDataRedirection] = useState();
    const [editDataRedirectionAff, setEditDataRedirectionAff] = useState();
    const [editDataAdServer, setEditDataAdServer] = useState()
    const [editDataSplit, setEditDataSplit] = useState(false);
    const [editDataAgeSystem, setEditDataAgeSystem] = useState(false);
    const [editDataCplSystem, setEditDataCplSystem] = useState(false);
    const [editDataCplLink, setEditDataCplLink] = useState();
    const [editDataEmailSystem, setEditDataEmailSystem] = useState(false);
    const [editDataAgeLink, setEditDataAgeLink] = useState();
    const [editDataEmailLink, setEditDataEmailLink] = useState();
    const [editDataEmailLinkOlder, setEditDataEmailLinkOlder] = useState();
    const [editDataPosition, setEditDataPosition] = useState();
    const [editDataCountries, setEditDataCountries] = useState();

    const editAd = (id) => {
        handleShowEdit();

        const response = ads.filter((ad) => ad.id === id);
        
        setEditDataId(id)
        setEditDataImage(response[0].image)
        setEditDataRedirection(response[0].redirection)
        setEditDataRedirectionAff(response[0].redirection_aff)
        setEditDataAdServer(response[0].adserver)
        setEditDataSplit(response[0].split)
        setEditDataAgeSystem(response[0].agesystem)
        setEditDataCplSystem(response[0].cplsystem)
        setEditDataEmailSystem(response[0].emailsystem)
        setEditDataCplLink(response[0].cpllink)
        setEditDataAgeLink(response[0].agelink)
        setEditDataEmailLink(response[0].emaillink)
        setEditDataEmailLinkOlder(response[0].emaillinkolder)
        setEditDataPosition(response[0].type)
        setEditDataCountries(response[0].countries)
    }

    const updateAd = async () => {
            let objIndex = ads.findIndex(((obj) => obj.id === editDataId));
            
            ads[objIndex] = {
                  "id": ads[objIndex].id || Math.floor(Math.random() * 999999) + 1,
                  "image": editDataImage,
                  "redirection": editDataRedirection,
                  "redirection_aff": editDataRedirectionAff,
                  "adserver": editDataAdServer,
                  "split": editDataSplit,
                  "agesystem": editDataAgeSystem,
                  "cplsystem": editDataCplSystem,
                  "emailsystem": editDataEmailSystem,
                  "agelink": editDataAgeLink,
                  "cpllink": editDataCplLink,
                  "emaillink": editDataEmailLink,
                  "emaillinkolder": editDataEmailLinkOlder,
                  "type": parseInt(editDataPosition),
                  "countries": editDataCountries,
            }
                              
            saveFile(ads);
            handleCloseEdit()

            setEditDataId('')
            setEditDataImage('')
            setEditDataRedirection('')
            setEditDataRedirectionAff('')
            setEditDataSplit('')
            setEditDataAgeSystem('')
            setEditDataCplSystem('')
            setEditDataEmailSystem('')
            setEditDataCplLink('')
            setEditDataAgeLink('')
            setEditDataEmailLink('')
            setEditDataEmailLinkOlder('')
            setEditDataPosition('')
    }

    // countries update
    const [countryCodeEdit, setCountryCodeEdit] = useState();
    const [countryIDEdit, setCountryIDEdit] = useState();
    const [countryLinkEdit, setCountryLinkEdit] = useState()

    const addCountriesEdit = () => {
        const countryObj = {
            gid: Math.floor(Math.random() * 9000000000) + 1000000000,
            code: countryCodeEdit,
            id: countryIDEdit,
            link: countryLinkEdit
        };
        
        if(!countryCodeEdit || !countryIDEdit || !countryLinkEdit){
            alert('All fields are required!')
            return;
        }

        setEditDataCountries((prev) => [...prev, countryObj])
        setCountryCodeEdit('')
        setCountryIDEdit('')
        setCountryLinkEdit('')
    }

    const deleteAdCountryEdit = (gid) => {
        const filter = editDataCountries.filter((sc) => sc.gid !== gid)
        setEditDataCountries(filter)
    }

    const [editAdCountriesActive, setEditAdCountriesActive] = useState(false);
    const [editAdCountriesActiveItem, setEditAdCountriesActiveItem] = useState();

    const [editAdCountriesActiveItemCountryCode, setEditAdCountriesActiveItemCountryCode] = useState();
    const [editAdCountriesActiveItemID, setEditAdCountriesActiveItemID] = useState();
    const [editAdCountriesActiveItemLink, setEditAdCountriesActiveItemLink] = useState();

    const editAdCountryEdit = (gid) => {
        const filter = editDataCountries.filter((edc) => edc.gid === gid);
        setEditAdCountriesActiveItem(filter[0])
        setEditAdCountriesActive(true)

        setEditAdCountriesActiveItemCountryCode(filter[0].code)
        setEditAdCountriesActiveItemID(filter[0].id)
        setEditAdCountriesActiveItemLink(filter[0].link)
    }

    const saveSingleCountryEdit = (gid) => {
        let objIndex = editDataCountries.findIndex(((obj) => obj.gid === gid));
            
        editDataCountries[objIndex] = {
              "code": editAdCountriesActiveItemCountryCode,
              "id": editAdCountriesActiveItemID,
              "link": editAdCountriesActiveItemLink
        }
                          
        setEditDataCountries(editDataCountries);

        setEditAdCountriesActive(false)
        setEditAdCountriesActiveItem('')
        setEditAdCountriesActiveItemCountryCode('')
        setEditAdCountriesActiveItemID('')
        setEditAdCountriesActiveItemLink('')
    }

    // iframes
    const [iframeUrl, setIframeUrl] = useState();
    const [iframeType, setIframeType] = useState();
    const [iframeTitle, setIframeTitle] = useState();

    const [iframes, setIframes] = useState([]);

    const getIframes = async () => {
        try {
          const response = await sendRequest(
            'https://gorillamediallc.com/onctesting/stored/data/iframes_list.json',
            'GET',
            null,
            {
              'Content-Type': 'application/json'
            }        
          )
    
          setIframes(response)
        } catch (err) {
          console.log(err)
        }
    }

    const addIframes = async (e) => {
        e.preventDefault();

        if(!iframeUrl || !iframeType || !iframeTitle){
            alert('All fields are required to add iframe!')
            return;
        } 

        const formObject = {
            id: Math.floor(Math.random() * 999999) + 1,
            url: iframeUrl,
            type: parseInt(iframeType),
            title: iframeTitle,
        }

        iframes.push(formObject)
        saveFileIframes(iframes)

        handleCloseIframe()
    }

    const deleteIframe = (id) => {
        const response = iframes.filter((iframe) => iframe.id !== id);

        setIframes(response)
        saveFileIframes(response)
    }

    const [showEditIframe, setShowEditIframe] = useState(false);
    const handleCloseEditIframe = () => setShowEditIframe(false);
    const handleShowEditIframe = () => setShowEditIframe(true);

    const [editDataIdIframe, setEditDataIdIframe] = useState();
    const [editDataUrlIframe, setEditDataUrlIframe] = useState();
    const [editDataTitleIframe, setEditDataTitleIframe] = useState();
    const [editDataPositionIframe, setEditDataPositionIframe] = useState();

    const editIframe = (id) => {
        handleShowEditIframe();

        const response = iframes.filter((iframe) => iframe.id === id);
        
        setEditDataIdIframe(id)
        setEditDataUrlIframe(response[0].url)
        setEditDataTitleIframe(response[0].title)
        setEditDataPositionIframe(response[0].type)
    }

    const updateIframe = () => {
        let objIndex = iframes.findIndex(((obj) => obj.id === editDataIdIframe));
        
        iframes[objIndex] = {
              "id": iframes[objIndex].id || Math.floor(Math.random() * 999999) + 1,
              "url": editDataUrlIframe,
              "title": editDataTitleIframe,
              "type": parseInt(editDataPositionIframe),
              "display": iframes[objIndex].display || false
        }
                          
        saveFileIframes(iframes);
        handleCloseEditIframe()

        setEditDataIdIframe('')
        setEditDataUrlIframe('')
        setEditDataTitleIframe('')
        setEditDataPositionIframe('')
    }

    const updateIframeToggle = (props) => {

        const found = iframes.some(iframe => iframe.display === true);
        const objIndex = iframes.findIndex(((obj) => obj.id === props.iframeid));

        if (found && iframes[objIndex].display === false) {
            alert('Error! There is already active Iframe!')
            return;
        } else {
                    
            iframes[objIndex] = {
                  ...iframes[objIndex],
                  display: props.display
            }
                              
            saveFileIframes(iframes);
        }

    }   

    // custom popovers
    const [customUrl, setCustomUrl] = useState();
    const [customUrlAff, setCustomUrlAff] = useState();
    const [customSplit, setCustomSplit] = useState(false);
    const [customImage, setCustomImage] = useState();
    const [customType, setCustomType] = useState();

    const [custom, setCustom] = useState([]);

    const getCustom = async () => {
        try {
          const response = await sendRequest(
            'https://gorillamediallc.com/onctesting/stored/data/custom_list.json',
            'GET',
            null,
            {
              'Content-Type': 'application/json'
            }        
          )
    
          setCustom(response)
        } catch (err) {
          console.log(err)
        }
    }

    const addCustom = (e) => {
        e.preventDefault();

        if(!customUrl || !customType) {
            alert('All fields are required!')
            return;
        }

        const formObject = {
            id: Math.floor(Math.random() * 999999) + 1,
            redirection: customUrl,
            redirection_aff: customUrlAff,
            split: customSplit,
            image: customImage,
            type: parseInt(customType), 
        }

        custom.push(formObject)
        saveFileCustom(custom)

        handleCloseCustom()
    }

    const deleteCustom = (id) => {
        const response = custom.filter((c) => c.id !== id);

        setCustom(response)
        saveFileCustom(response)
    }

    const [showEditCustom, setShowEditCustom] = useState(false);
    const handleCloseEditCustom = () => setShowEditCustom(false);
    const handleShowEditCustom = () => setShowEditCustom(true);

    const [editDataIdCustom, setEditDataIdCustom] = useState();
    const [editDataImageCustom, setEditDataImageCustom] = useState();
    const [editDataRedirectionCustom, setEditDataRedirectionCustom] = useState();
    const [editDataRedirectionCustomAff, setEditDataRedirectionCustomAff] = useState();
    const [editDataCustomSplit, setEditDataCustomSplit] = useState();
    const [editDataPositionCustom, setEditDataPositionCustom] = useState();

    const editCustom = (id) => {
        handleShowEditCustom();

        const response = custom.filter((c) => c.id === id);
        
        setEditDataIdCustom(id)
        setEditDataImageCustom(response[0].image)
        setEditDataRedirectionCustom(response[0].redirection)
        setEditDataRedirectionCustomAff(response[0].redirection_aff)
        setEditDataCustomSplit(response[0].split)
        setEditDataPositionCustom(response[0].type)
    }

    const updateCustom = async () => {
            let objIndex = custom.findIndex(((obj) => obj.id === editDataIdCustom));
            
            custom[objIndex] = {
                  "id": custom[objIndex].id || Math.floor(Math.random() * 999999) + 1,
                  "image": editDataImageCustom,
                  "redirection": editDataRedirectionCustom,
                  "redirection_aff": editDataRedirectionCustomAff,
                  "split": editDataCustomSplit,
                  "type": parseInt(editDataPositionCustom)
            }
                              
            saveFileCustom(custom);
            handleCloseEditCustom()

            setEditDataIdCustom('')
            setEditDataImageCustom('')
            setEditDataRedirectionCustom('')
            setEditDataPositionCustom('')
    }

    // end
    useEffect(() => {
        if(accesed){
            getAds()
            getWebAds()
            getIframes()
            getCustom()
            fetchTags()
        }
    }, [accesed]);


    // tags
    const [showTags, setShowTags] = useState(false);
    const handleCloseTags = () => setShowTags(false);
    const handleShowTags = () => {
        getUsersData();
        setShowTags(true);  
    }

    const animatedComponents = makeAnimated();
    const customStyles = {
        control: (base, state) => ({
          ...base,
          background: "#111",
          borderRadius: state.isFocused ? "3px 3px 0 0" : 3,
          borderColor: state.isFocused ? "purple" : "purple",
          boxShadow: state.isFocused ? null : null,
          "&:hover": {
            borderColor: state.isFocused ? "purple" : "purple",
            background: "black",
            color: "white"
          }
        }),
        option: (base, state) => ({
            "&:hover": {
                borderColor: state.isFocused ? "purple" : "purple",
                background: "black",
                color: "white"
              }    
        }),
        menu: base => ({
          ...base,
          borderRadius: 0,
          marginTop: 0
        }),
        menuList: (base, state) => ({
          ...base,
          background: "purple",
          padding: "0.25rem",
        })
    };
      

    const [usersData, setUsersData] = useState([]);
    const [tagName, setTagName] = useState();
    const [tagMembers, setTagMembers] = useState([])
    
    const getUsersData = async () => {
        try {
            const response = await sendRequest('./data/users.json');
            
            setUsersData(response)
        } catch (err) {
            console.log(err);
        }
    }

    const options = []
    useEffect(() => {
        usersData && usersData.map((user) => {
            options.push({
               value: user.nick,
               label: user.nick
           });
       });
   }, [usersData, options]);

    const [tags, setTags] = useState([])

    const createTagsFinish = async (event) => {
        event.preventDefault();

        if(!tagName || !tagMembers) {
            alert('All fields are required!')
            return;
        }

        const obj = {
            name: tagName,
            members: tagMembers,
            active: true
        }

        tags.push(obj)
        saveFileTags(tags)

        handleCloseTags()
    }

    const handleChange = (newValue) => {
        const newValuesArr = newValue ? newValue.map(item => item.value) : [];
        setTagMembers(newValuesArr);
    };

    const fetchTags = async () => {
        try {
          const response = await sendRequest(
            'https://gorillamediallc.com/onctesting/stored/data/tags.json',
            'GET',
            null,
            {
              'Content-Type': 'application/json'
            }        
          )
    
          setTags(response)
        } catch (err) {
          console.log(err)
        }
    }

    const deleteTag = (name) => {
        const response = tags.filter((tag) => tag.name !== name);

        setTags(response)
        saveFileTags(response)
    }


    const updateTagsToggle = (props) => {

        const objIndex = tags.findIndex(((obj) => obj.name === props.tagname));
    
        tags[objIndex] = {
                  ...tags[objIndex],
                  active: props.active
        }
            
        saveFileTags(tags);
    }   

    const [showEditTag, setShowEditTag] = useState(false);
    const handleCloseEditTag = () => setShowEditTag(false);
    const handleShowEditTag = () => setShowEditTag(true);

    const [editDataNameTag, setEditDataNameTag] = useState();
    const [editDataMembersTag, setEditDataMembersTag] = useState();
    const [editMembersTagList, setEditMembersTagList] = useState();

    const handleChangeUpdate  = (newValue) => {
        const newValuesArr = newValue ? newValue.map(item => item.value) : [];
        setEditMembersTagList(newValuesArr);
    };

    const editTag = (name) => {
        handleShowEditTag();

        const response = tags.filter((tag) => tag.name === name);

        getUsersData()
        
        const tags_members = [];

        if(response && response[0].members && response[0].members.map((m) => {
            const updObj = {
                label: m,
                value: m
            }

            tags_members.push(updObj)
        }))

        setEditDataNameTag(response[0].name)
        setEditDataMembersTag(tags_members)
    }

    const updateTag = () => {
        let objIndex = tags.findIndex(((obj) => obj.name === editDataNameTag));
        
        tags[objIndex] = {
              "name": editDataNameTag,
              "members": editMembersTagList,
              "active": tags[objIndex].active || false
        }
                          
        saveFileTags(tags);
        handleCloseEditTag()

        setEditDataNameTag('')
        setEditDataMembersTag('')
    }

    // web ads tab
    const [webAds, setWebAds] = useState([]);

    const [imageWeb, setImageWeb] = useState();
    const [redirectionWeb, setRedirectionWeb] = useState();
    const [typeWeb, setTypeWeb] = useState();
    const [originWeb, setOriginWeb] = useState();

    const addWebAds = (e) => {
        e.preventDefault();

        if(!imageWeb || !redirectionWeb || !typeWeb || !originWeb) {
            alert('All fields are required to add web ad!')
            return;
        }

        const formObject = {
            id: Math.floor(Math.random() * 999999) + 1,
            image: imageWeb,
            redirection: redirectionWeb,
            type: typeWeb,
            origin: originWeb
        }

        webAds.push(formObject)
        saveFileWeb(webAds)

        handleCloseWeb()
    }

    const getWebAds = async () => {
        try {
          const response = await sendRequest(
            'https://gorillamediallc.com/onctesting/stored/data/webads_list.json',
            'GET',
            null,
            {
              'Content-Type': 'application/json'
            }        
          )
    
          setWebAds(response)
        } catch (err) {
          console.log(err)
        }
    }


    const deleteWebAd = (id) => {
        const response = webAds.filter((web) => web.id !== id);

        setWebAds(response)
        saveFileWeb(response)
    }

    const [showEditWeb, setShowEditWeb] = useState(false);
    const handleCloseEditWeb = () => setShowEditWeb(false);
    const handleShowEditWeb = () => setShowEditWeb(true);

    const [editDataIdWeb, setEditDataIdWeb] = useState();
    const [editDataImageWeb, setEditDataImageWeb] = useState();
    const [editDataRedirectionWeb, setEditDataRedirectionWeb] = useState();
    const [editDataPositionWeb, setEditDataPositionWeb] = useState();
    const [editDataOriginWeb, setEditDataOriginWeb] = useState();

    const editWebAd = (id) => {
        handleShowEditWeb();

        const response = webAds.filter((web) => web.id === id);
        
        setEditDataIdWeb(id)
        setEditDataImageWeb(response[0].image)
        setEditDataRedirectionWeb(response[0].redirection)
        setEditDataPositionWeb(response[0].type)
        setEditDataOriginWeb(response[0].origin)

        const filter = origins.filter((o) => o.origin === response[0].origin);
        setDisplayOriginItems(filter[0]?.values)
    }

    const updateAdWeb = async () => {
            let objIndex = webAds.findIndex(((obj) => obj.id === editDataIdWeb));
            
            webAds[objIndex] = {
                  "id": webAds[objIndex].id || Math.floor(Math.random() * 999999) + 1,
                  "image": editDataImageWeb,
                  "redirection": editDataRedirectionWeb,
                  "type": editDataPositionWeb,
                  "origin": editDataOriginWeb
            }
                              
            saveFileWeb(webAds);
            handleCloseEditWeb()

            setEditDataIdWeb('')
            setEditDataImageWeb('')
            setEditDataRedirectionWeb('')
            setEditDataPositionWeb('')
            setEditDataOriginWeb('')
    }

    // testing
    let os = [
        {
            origin: "https://bangbase.chat",
            values: ["bottom", "rows", "popover"]
        }
    ]

    const [origins, setOrigins] = useState(os)

    const [displayOriginItems, setDisplayOriginItems] = useState();
    const updateOrigin = (value) => {
        setOriginWeb(value)
        setEditDataOriginWeb(value)

        const filter = origins.filter((o) => o.origin === value);
        setDisplayOriginItems(filter[0]?.values)
    }
    
    return (
        <React.Fragment>
            <Modal show={showEditWeb} onHide={handleCloseEditWeb} className="custom_adsmodal">
                <Modal.Header closeButton>
                <Modal.Title>Edit Web Ad</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                    <Form.Group className="mb-3" controlId="exampleForm.ControlInputImage">
                        <Form.Label>Direct url: <small>(image/gif ...)</small></Form.Label>
                        <Form.Control value={editDataImageWeb} onChange={(e) => setEditDataImageWeb(e.target.value)} type="text" />
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="exampleForm.ControlInputRedirect">
                        <Form.Label>Link:</Form.Label>
                        <Form.Control value={editDataRedirectionWeb} onChange={(e) => setEditDataRedirectionWeb(e.target.value)} type="text" />
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="exampleForm.ControlInputRedirect">
                        <Form.Label>Origins:</Form.Label>
                        <Form.Select defaultValue={editDataOriginWeb} onChange={(e) => updateOrigin(e.target.value)}>
                        <option value="">Select origin</option>
                        {origins && origins.map((o, index) => {
                            return (
                                <React.Fragment key={`origin` + index}>
                                    <option value={o.origin} key={`opt_origin` + index}>{o.origin}</option>
                                </React.Fragment>
                            )
                        })}
                        </Form.Select>
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="exampleForm.ControlInputPosition">
                        <Form.Label>Position:</Form.Label>
                        <Form.Select defaultValue={editDataPositionWeb} onChange={(e) => setEditDataPositionWeb(e.target.value)}>
                        <option value="">Select position</option>
                        {displayOriginItems && displayOriginItems.map((item, index) => {
                            return (
                                <React.Fragment key={`poscx` + index}>
                                <option value={item} key={`opt_item` + index}>{item}</option>
                                </React.Fragment>
                            )
                        })}
                        </Form.Select>
                    </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                <Button variant="secondary" onClick={handleCloseEditWeb}>
                    Close
                </Button>
                <Button variant="success" onClick={() => updateAdWeb(editDataIdWeb)}>
                    Save
                </Button>
                </Modal.Footer>
            </Modal>

            <Modal show={showEdit} onHide={handleCloseEdit} className="custom_adsmodal_split">
                <Modal.Header closeButton>
                <Modal.Title>Edit Ad</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                    <Form.Group className="mb-3" controlId="exampleForm.ControlInputImage">
                        <Form.Label>Direct url: <small>(image/gif ...)</small></Form.Label>
                        <Form.Control className="split_input" value={editDataImage} onChange={(e) => setEditDataImage(e.target.value)} type="text" />
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="exampleForm.ControlInputRedirect">
                        <Form.Label>Link:</Form.Label>
                        <Form.Control className="split_input" value={editDataRedirection} onChange={(e) => setEditDataRedirection(e.target.value)} type="text" />
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="exampleForm.ControlInputRedirect">
                        <Form.Label>Aff link:</Form.Label>
                        <Form.Control className="split_input" value={editDataRedirectionAff} onChange={(e) => setEditDataRedirectionAff(e.target.value)} type="text" />
                    </Form.Group>
                    <Form.Group className="mb-3" controlId={`split-1status` + editDataId}>
                    <Form.Label>Adserver:</Form.Label>                   
                    <Form.Check 
                                                    type="switch"
                                                    id={`split-1status` + editDataAdServer}
                                                    className="form-check"
                                                    onChange={(e) => setEditDataAdServer(e.target.checked)}
                                                    checked={editDataAdServer}
                    />
                    </Form.Group>
                    <Form.Group className="mb-3" controlId={`split-1status` + editDataId}>
                    <Form.Label>Split system:</Form.Label>                   
                    <Form.Check 
                                                    type="switch"
                                                    id={`split-1status` + editDataId}
                                                    className="form-check"
                                                    onChange={(e) => setEditDataSplit(e.target.checked)}
                                                    checked={editDataSplit}
                    />
                    </Form.Group>
                    <Form.Group className="mb-3" controlId={`age-1statuscpl` + editDataId}>
                    <Form.Label>CPL system:</Form.Label>                   
                    <Form.Check 
                                                    type="switch"
                                                    id={`age-1statuscpl` + editDataId}
                                                    className="form-check"
                                                    onChange={(e) => setEditDataCplSystem(e.target.checked)}
                                                    checked={editDataCplSystem}
                    />
                    {editDataCplSystem && (
                        <>
                        <Form.Label>CPL System Link:</Form.Label>
                        <Form.Control className="split_input" value={editDataCplLink} onChange={(e) => setEditDataCplLink(e.target.value)} type="text" />
                        </>
                    )}
                    </Form.Group>
                    <Form.Group className="mb-3" controlId={`age-1status` + editDataId}>
                    <Form.Label>Age system:</Form.Label>                   
                    <Form.Check 
                                                    type="switch"
                                                    id={`age-1status` + editDataId}
                                                    className="form-check"
                                                    onChange={(e) => setEditDataAgeSystem(e.target.checked)}
                                                    checked={editDataAgeSystem}
                    />
                    {editDataAgeSystem && (
                        <>
                        <Form.Label>Age System Link:</Form.Label>                   
                        <Form.Control className="split_input" value={editDataAgeLink} onChange={(e) => setEditDataAgeLink(e.target.value)} type="text" />
                        </>
                    )}
                    </Form.Group>

                    <Form.Group className="mb-3" controlId={`emailsystem-1status` + editDataId}>
                    <Form.Label>Email system:</Form.Label>                   
                    <Form.Check 
                                                    type="switch"
                                                    id={`emailsystem-1status` + editDataId}
                                                    className="form-check"
                                                    onChange={(e) => setEditDataEmailSystem(e.target.checked)}
                                                    checked={editDataEmailSystem}
                    />
                    {editDataEmailSystem && (
                        <>
                        <Form.Label>Email Link:</Form.Label>
                        <Form.Control className="split_input" value={editDataEmailLink} onChange={(e) => setEditDataEmailLink(e.target.value)} type="text" />

                        <Form.Label>Email Link older:</Form.Label>
                        <Form.Control className="split_input" value={editDataEmailLinkOlder} onChange={(e) => setEditDataEmailLinkOlder(e.target.value)} type="text" />

                        </>
                    )}
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="exampleForm.ControlInputPosition">
                        <Form.Label>Position:</Form.Label>
                        <Form.Select className="split_input" defaultValue={editDataPosition} onChange={(e) => setEditDataPosition(e.target.value)}>
                        <option value="">Select position</option>
                        <option value="1">Top side</option>
                        <option value="2">Left side</option>
                        <option value="3">Right side</option>
                        <option value="4">Bottom side</option>
                        <option value="5">Popover</option>
                        <option value="6">Slide-in chat notification</option>
                        <option value="7">Intro Popover</option>
                        <option value="8">Fuck Now</option>
                        </Form.Select>
                    </Form.Group>
                    </Form>

                    <h3>Countries</h3>     
                    <Row>
                        <Col sm={2}>
                            <Form.Group className="mb-3" controlId="exampleForm.ControlInputCountryCode">
                                <Form.Label>Country Code:</Form.Label>
                                <Form.Control className="split_input" value={countryCodeEdit} onChange={(e) => setCountryCodeEdit(e.target.value)} type="text" />
                            </Form.Group>
                        </Col>
                        <Col sm={2}>
                            <Form.Group className="mb-3" controlId="exampleForm.ControlInputCountryId">
                                <Form.Label>ID:</Form.Label>

                                <Form.Select aria-label="ID" value={countryIDEdit} className="split_input" onChange={(e) => setCountryIDEdit(e.target.value)}>
                                    <option>Select</option>
                                    <option value="Link">Link</option>
                                    <option value="AffLink">Aff Link</option>
                                    <option value="AgeSystemLink">Age System Link</option>
                                    <option value="CplSystemLink">CPL System Link</option>
                                    <option value="EmailLinkDefault">Email Link Default</option>
                                    <option value="EmailLinkOlder">Email Link Older</option>
                                </Form.Select>
                            </Form.Group>  
                        </Col>
                        <Col sm={8}>
                            <Form.Group className="mb-3" controlId="exampleForm.ControlInputCountryLink">
                                <Form.Label>Country Link:</Form.Label>
                                <Form.Control className="split_input" value={countryLinkEdit} onChange={(e) => setCountryLinkEdit(e.target.value)} type="text" />                        
                            </Form.Group>
                        </Col>
                    </Row>    
                    <Button variant='success' size='sm' onClick={addCountriesEdit}>Add</Button>

                    {editDataCountries && editDataCountries.map((sc, index) => {
                        const editStatus = editAdCountriesActive && editAdCountriesActiveItem.gid === sc.gid

                        return (
                            <React.Fragment key={`sc` + index}>
                                <div className="country-code-block">
                                {editStatus ? (
                                    <>
                                    <Row>
                                        <Col sm={2}>
                                            <Form.Group className="mb-3" controlId="exampleForm.ControlInputCountryCode">
                                                <Form.Label>Country Code:</Form.Label>
                                                <Form.Control className="split_input" value={editAdCountriesActiveItemCountryCode} onChange={(e) => setEditAdCountriesActiveItemCountryCode(e.target.value)} type="text" />
                                            </Form.Group>
                                        </Col>
                                        <Col sm={2}>
                                            <Form.Group className="mb-3" controlId="exampleForm.ControlInputCountryId">
                                                <Form.Label>ID:</Form.Label>

                                                <Form.Select aria-label="ID" value={editAdCountriesActiveItemID} className="split_input" onChange={(e) => setEditAdCountriesActiveItemID(e.target.value)}>
                                                    <option>Select</option>
                                                    <option value="Link">Link</option>
                                                    <option value="AffLink">Aff Link</option>
                                                    <option value="AgeSystemLink">Age System Link</option>
                                                    <option value="CplSystemLink">CPL System Link</option>
                                                    <option value="EmailLinkDefault">Email Link Default</option>
                                                    <option value="EmailLinkOlder">Email Link Older</option>
                                                </Form.Select>
                                            </Form.Group>  
                                        </Col>
                                        <Col sm={8}>
                                            <Form.Group className="mb-3" controlId="exampleForm.ControlInputCountryLink">
                                                <Form.Label>Country Link:</Form.Label>
                                                <Form.Control className="split_input" value={editAdCountriesActiveItemLink} onChange={(e) => setEditAdCountriesActiveItemLink(e.target.value)} type="text" />                        
                                            </Form.Group>
                                        </Col>
                                    </Row>    
                                    <Button variant='success' size='sm' onClick={() => saveSingleCountryEdit(sc.gid)}>SAVE</Button>
                                    </>
                                ) : (
                                    <>
                                    <Row>
                                        <Col sm={2}>
                                            {sc.code}
                                        </Col>
                                        <Col sm={2}>
                                            {sc.id}
                                        </Col>
                                        <Col sm={7}>
                                            {sc.link}
                                        </Col>
                                        <Col sm={1}>
                                            <Button variant="danger" size='sm' onClick={() => deleteAdCountryEdit(sc.gid)}> <i className="fa-solid fa-ban"></i> </Button>
                                            <Button variant="warning" size='sm' onClick={() => editAdCountryEdit(sc.gid)}> <i className="fa-solid fa-pencil"></i> </Button>
                                        </Col>
                                    </Row>
                                    </>
                                )}
                                </div>
                            </React.Fragment>
                        )
                    })}

                </Modal.Body>
                <Modal.Footer>
                <Button variant="secondary" onClick={handleCloseEdit}>
                    Close
                </Button>
                <Button variant="success" onClick={() => updateAd(editDataId)}>
                    Save
                </Button>
                </Modal.Footer>
            </Modal>

            <Modal show={showEditIframe} onHide={handleCloseEditIframe} className="custom_adsmodal">
                <Modal.Header closeButton>
                <Modal.Title>Edit Iframe</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                    <Form.Group className="mb-3" controlId="exampleForm.ControlInputUrl">
                        <Form.Label>Direct url: <small>(iframe url ...)</small></Form.Label>
                        <Form.Control value={editDataUrlIframe} onChange={(e) => setEditDataUrlIframe(e.target.value)} type="text" />
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="exampleForm.ControlInputTitle">
                        <Form.Label>Title:</Form.Label>
                        <Form.Control value={editDataTitleIframe} onChange={(e) => setEditDataTitleIframe(e.target.value)} type="text" />
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="exampleForm.ControlInputPosition">
                        <Form.Label>Position:</Form.Label>
                        <Form.Select defaultValue={editDataPositionIframe} onChange={(e) => setEditDataPositionIframe(e.target.value)}>
                        <option value="">Select position</option>
                        <option value="1">Global</option>
                        <option value="2">Normal</option>
                        </Form.Select>
                    </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                <Button variant="secondary" onClick={handleCloseEditIframe}>
                    Close
                </Button>
                <Button variant="success" onClick={() => updateIframe(editDataIdIframe)}>
                    Save
                </Button>
                </Modal.Footer>
            </Modal>

            <Modal show={showEditCustom} onHide={handleCloseEditCustom} className="custom_adsmodal_split">
                <Modal.Header closeButton>
                <Modal.Title>Edit Custom</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                    <Form.Group className="mb-3" controlId="exampleForm.ControlInputImage">
                        <Form.Label>Direct url: <small>(image/gif ...)</small></Form.Label>
                        <Form.Control className="split_input" value={editDataImageCustom} onChange={(e) => setEditDataImageCustom(e.target.value)} type="text" />
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="exampleForm.ControlInputRedirect">
                        <Form.Label>Link:</Form.Label>
                        <Form.Control className="split_input" value={editDataRedirectionCustom} onChange={(e) => setEditDataRedirectionCustom(e.target.value)} type="text" />
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="exampleForm.ControlInputRedirect">
                        <Form.Label>Aff link:</Form.Label>
                        <Form.Control className="split_input" value={editDataRedirectionCustomAff} onChange={(e) => setEditDataRedirectionCustomAff(e.target.value)} type="text" />
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="split-status">
                    <Form.Label>Split system:</Form.Label>                   
                    <Form.Check 
                                                    type="switch"
                                                    id="split-status"
                                                    className="form-check"
                                                    onChange={(e) => setEditDataCustomSplit(e.target.checked)}
                                                    checked={editDataCustomSplit}
                    />
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="exampleForm.ControlInputPosition">
                        <Form.Label>Position:</Form.Label>
                        <Form.Select className="split_input" defaultValue={editDataPositionCustom} onChange={(e) => setEditDataPositionCustom(e.target.value)}>
                        <option value="">Select position</option>
                        <option value="1">Chat notification</option>
                        <option value="2">Native ads and view profile buttons</option>
                        <option value="3">Send message</option>
                        </Form.Select>
                    </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                <Button variant="secondary" onClick={handleCloseEditCustom}>
                    Close
                </Button>
                <Button variant="success" onClick={() => updateCustom(editDataIdCustom)}>
                    Save
                </Button>
                </Modal.Footer>
            </Modal>

            <Modal show={showEditTag} onHide={handleCloseEditTag} className="custom_adsmodal">
                <Modal.Header closeButton>
                <Modal.Title>Edit Tag</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                    <Form.Group className="mb-3" controlId="exampleForm.ControlInputN">
                        <Form.Label>Tag name:</Form.Label>
                        <Form.Control value={editDataNameTag} onChange={(e) => setEditDataNameTag(e.target.value)} type="text" />
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Label>Tag members:</Form.Label>
                        <Select
                        closeMenuOnSelect={true}
                        components={animatedComponents}
                        isMulti
                        options={options}
                        defaultValue={editDataMembersTag}
                        onChange={handleChangeUpdate}
                        className="custom-reactselect"
                        styles={customStyles}
                        
                        />
                    </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                <Button variant="secondary" onClick={handleCloseEditTag}>
                    Close
                </Button>
                <Button variant="success" onClick={() => updateTag(editDataNameTag)}>
                    Save
                </Button>
                </Modal.Footer>
            </Modal>

            <Modal show={show} onHide={handleClose} className="custom_adsmodal_split">
                <Modal.Header closeButton>
                <Modal.Title>Add Ad</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                    <Form.Group className="mb-3" controlId="exampleForm.ControlInputImage">
                        <Form.Label>Direct url: <small>(image/gif ...)</small></Form.Label>
                        <Form.Control className="split_input" onChange={(e) => setImage(e.target.value)} type="text" />
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="exampleForm.ControlInputRedirect">
                        <Form.Label>Link:</Form.Label>
                        <Form.Control className="split_input" onChange={(e) => setRedirection(e.target.value)} type="text" />
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="exampleForm.ControlInputRedirect">
                        <Form.Label>Aff Link:</Form.Label>
                        <Form.Control className="split_input" onChange={(e) => setRedirectionAff(e.target.value)} type="text" />
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="adserver-status">
                    <Form.Label>Adserver:</Form.Label>                   
                    <Form.Check 
                                                    type="switch"
                                                    id="adserver-status"
                                                    className="form-check"
                                                    onChange={(e) => setAdServer(e.target.checked)}
                    />
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="split-status">
                    <Form.Label>Split system:</Form.Label>                   
                    <Form.Check 
                                                    type="switch"
                                                    id="split-status"
                                                    className="form-check"
                                                    onChange={(e) => setSplit(e.target.checked)}
                    />
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="age-statuscpl">
                    <Form.Label>CPL system:</Form.Label>                   
                    <Form.Check 
                                                    type="switch"
                                                    id="age-statuscpl"
                                                    className="form-check"
                                                    onChange={(e) => setCplSystem(e.target.checked)}
                    />

                    {cplSystem && (
                        <>
                        <Form.Label>CPL System Link:</Form.Label>
                        <Form.Control className="split_input" onChange={(e) => setCplLink(e.target.value)} type="text" />
                        </>
                    )}   
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="age-status">
                    <Form.Label>Age system:</Form.Label>                   
                    <Form.Check 
                                                    type="switch"
                                                    id="age-status"
                                                    className="form-check"
                                                    onChange={(e) => setAgeSystem(e.target.checked)}
                    />
                    {ageSystem && (
                        <>
                        <Form.Label>Age System Link:</Form.Label>                   
                        <Form.Control className="split_input" onChange={(e) => setAgeLink(e.target.value)} type="text" />
                        </>
                    )}
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="email-status">
                    <Form.Label>Email system:</Form.Label>                   
                    <Form.Check 
                                                    type="switch"
                                                    id="email-status"
                                                    className="form-check"
                                                    onChange={(e) => setEmailSystem(e.target.checked)}
                    />

                    {emailSystem && (
                        <>
                        <Form.Group className="mb-3" controlId="exampleForm.ControlInputRedirect">
                            <Form.Label>Email Link Default:</Form.Label>
                            <Form.Control className="split_input" onChange={(e) => setEmailLink(e.target.value)} type="text" />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="exampleForm.ControlInputRedirect">
                            <Form.Label>Email Link Older:</Form.Label>
                            <Form.Control className="split_input" onChange={(e) => setEmailLinkOlder(e.target.value)} type="text" />
                        </Form.Group>
                        </>
                    )}

                    </Form.Group>

                    <Form.Group className="mb-3" controlId="exampleForm.ControlInputPosition">
                        <Form.Label>Position:</Form.Label>
                        <Form.Select className="split_input" onChange={(e) => setType(e.target.value)}>
                        <option value="">Select position</option>
                        <option value="1">Top side</option>
                        <option value="2">Left side</option>
                        <option value="3">Right side</option>
                        <option value="4">Bottom side</option>
                        <option value="5">Popover</option>
                        <option value="6">Slide-in chat notification</option>
                        <option value="7">Intro Popover</option>
                        <option value="8">Fuck Now</option>
                        </Form.Select>
                    </Form.Group>
                    </Form>

                    <h3>Countries</h3>     
                    <Row>
                        <Col>
                            <Form.Group className="mb-3" controlId="exampleForm.ControlInputCountryCode">
                                <Form.Label>Country Code:</Form.Label>
                                <Form.Control className="split_input" value={countryCode} onChange={(e) => setCountryCode(e.target.value)} type="text" />
                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group className="mb-3" controlId="exampleForm.ControlInputCountryId">
                                <Form.Label>ID:</Form.Label>

                                <Form.Select aria-label="ID" value={countryID} className="split_input" onChange={(e) => setCountryID(e.target.value)}>
                                    <option>Select</option>
                                    <option value="Link">Link</option>
                                    <option value="AffLink">Aff Link</option>
                                    <option value="AgeSystemLink">Age System Link</option>
                                    <option value="EmailLinkDefault">Email Link Default</option>
                                    <option value="EmailLinkOlder">Email Link Older</option>
                                </Form.Select>
                            </Form.Group>  
                        </Col>
                        <Col>
                            <Form.Group className="mb-3" controlId="exampleForm.ControlInputCountryLink">
                                <Form.Label>Country Link:</Form.Label>
                                <Form.Control className="split_input" value={countryLink} onChange={(e) => setCountryLink(e.target.value)} type="text" />                        
                            </Form.Group>
                        </Col>
                    </Row>    
                    <Button variant='success' size='sm' onClick={addCountries}>Add</Button>

                    {storeCountries && storeCountries.map((sc, index) => {
                        console.log(sc)
                        return (
                            <React.Fragment key={`sc` + index}>
                                <div className="country-code-block">
                                <Row>
                                    <Col>
                                        {sc.code}
                                    </Col>
                                    <Col>
                                        {sc.id}
                                    </Col>
                                    <Col>
                                        {sc.link}
                                    </Col>
                                    <Col>
                                        <Button variant="danger" size='sm' onClick={() => deleteAdCountry(sc.gid)}> <i className="fa-solid fa-ban"></i> </Button>
                                    </Col>
                                </Row>
                                </div>
                            </React.Fragment>
                        )
                    })}
                </Modal.Body>
                <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    Close
                </Button>
                <Button variant="success" onClick={addAds}>
                    Save
                </Button>
                </Modal.Footer>
            </Modal>

            <Modal show={showIframe} onHide={handleCloseIframe} className="custom_adsmodal">
                <Modal.Header closeButton>
                <Modal.Title>Add Iframe</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                    <Form.Group className="mb-3" controlId="exampleForm.ControlInputUrl">
                        <Form.Label>Direct url: <small>(iframe url)</small></Form.Label>
                        <Form.Control onChange={(e) => setIframeUrl(e.target.value)} type="text" />
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="exampleForm.ControlInputPosition">
                        <Form.Label>Position:</Form.Label>
                        <Form.Select onChange={(e) => setIframeType(e.target.value)}>
                        <option value="">Select position</option>
                        <option value="1">Global</option>
                        <option value="2">Normal</option>
                        </Form.Select>
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="exampleForm.ControlInputTitle">
                        <Form.Label>Title:</Form.Label>
                        <Form.Control onChange={(e) => setIframeTitle(e.target.value)} type="text" />
                    </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                <Button variant="secondary" onClick={handleCloseIframe}>
                    Close
                </Button>
                <Button variant="success" onClick={addIframes}>
                    Save
                </Button>
                </Modal.Footer>
            </Modal>

            <Modal show={showSettings} onHide={handleCloseSettings} className="custom_adsmodal">
                <Modal.Header closeButton>
                <Modal.Title>Settings</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                    <Form.Group className="mb-3" controlId="exampleForm.ControlInputx1">
                        <Form.Label>Top side: <small>(max display)</small></Form.Label>
                        <Form.Control defaultValue={top && top} onChange={(e) => setTop(e.target.value)} type="number" />
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="exampleForm.ControlInputx2">
                        <Form.Label>Left side: <small>(max display)</small></Form.Label>
                        <Form.Control defaultValue={left && left} onChange={(e) => setLeft(e.target.value)} type="number" />
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="exampleForm.ControlInputx3">
                        <Form.Label>Right side: <small>(max display)</small></Form.Label>
                        <Form.Control defaultValue={right && right} onChange={(e) => setRight(e.target.value)} type="number" />
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="exampleForm.ControlInputx4">
                        <Form.Label>Bottom side: <small>(max display)</small></Form.Label>
                        <Form.Control defaultValue={bottom && bottom} onChange={(e) => setBottom(e.target.value)} type="number" />
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="exampleForm.ControlInputx5">
                        <Form.Label>Popover: <small>(max display)</small></Form.Label>
                        <Form.Control defaultValue={popover && popover} onChange={(e) => setPopover(e.target.value)} type="number" />
                    </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                <Button variant="secondary" onClick={handleCloseSettings}>
                    Close
                </Button>
                <Button variant="success" onClick={saveSettings}>
                    Save
                </Button>
                </Modal.Footer>
            </Modal>

            <Modal show={showCustom} onHide={handleCloseCustom} className="custom_adsmodal">
                <Modal.Header closeButton>
                <Modal.Title>Add Custom</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                    <Form.Group className="mb-3" controlId="exampleForm.ControlInputImage">
                        <Form.Label>Direct url: <small>(image/gif ...)</small></Form.Label>
                        <Form.Control onChange={(e) => setCustomImage(e.target.value)} type="text" />
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="exampleForm.ControlInputRedirect">
                        <Form.Label>Link:</Form.Label>
                        <Form.Control onChange={(e) => setCustomUrl(e.target.value)} type="text" />
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="exampleForm.ControlInputRedirect">
                        <Form.Label>Aff Link:</Form.Label>
                        <Form.Control onChange={(e) => setCustomUrlAff(e.target.value)} type="text" />
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="split-status-custom">
                    <Form.Label>Split system:</Form.Label>                   
                    <Form.Check 
                                                    type="switch"
                                                    id="split-status-custom"
                                                    className="form-check"
                                                    onChange={(e) => setCustomSplit(e.target.checked)}
                    />
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="exampleForm.ControlInputPosition">
                        <Form.Label>Position:</Form.Label>
                        <Form.Select onChange={(e) => setCustomType(e.target.value)}>
                        <option value="">Select position</option>
                        <option value="1">Chat notification</option>
                        <option value="2">Native ads and view profile buttons</option>
                        <option value="3">Send message</option>
                        </Form.Select>
                    </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                <Button variant="secondary" onClick={handleCloseCustom}>
                    Close
                </Button>
                <Button variant="success" onClick={addCustom}>
                    Save
                </Button>
                </Modal.Footer>
            </Modal>

            <Modal show={showTags} onHide={handleCloseCustom} className="custom_adsmodal">
                <Modal.Header closeButton>
                <Modal.Title>Create tags</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                    <Form.Group className="mb-3" controlId="exampleForm.ControlInputTagName">
                        <Form.Label>Tag name:</Form.Label>
                        <Form.Control onChange={(e) => setTagName(e.target.value)} type="text" />
                        <Form.Text id="exampleForm.ControlInputTagName" muted>
                            Tag should always start with # and avoid blank spaces. <br />
                            Example: #milf, #ebony, #young-girls ...
                        </Form.Text>
                    </Form.Group>

                    <Form.Group className="mb-3">
                        <Form.Label>Tag members:</Form.Label>
                        <Select
                        closeMenuOnSelect={true}
                        components={animatedComponents}
                        isMulti
                        options={options}
                        onChange={handleChange}
                        className="custom-reactselect"
                        styles={customStyles}
                        />
                    </Form.Group>
    
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                <Button variant="secondary" onClick={handleCloseTags}>
                    Close
                </Button>
                <Button variant="success" onClick={createTagsFinish}>
                    Save
                </Button>
                </Modal.Footer>
            </Modal>

            <Modal show={showWeb} onHide={handleCloseWeb} className="custom_adsmodal">
                <Modal.Header closeButton>
                <Modal.Title>Add Web Ad</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                    <Form.Group className="mb-3" controlId="exampleForm.ControlInputImage">
                        <Form.Label>Direct url: <small>(image/gif ...)</small></Form.Label>
                        <Form.Control onChange={(e) => setImageWeb(e.target.value)} type="text" />
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="exampleForm.ControlInputRedirect">
                        <Form.Label>Link:</Form.Label>
                        <Form.Control onChange={(e) => setRedirectionWeb(e.target.value)} type="text" />
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="exampleForm.ControlInputRedirect">
                        <Form.Label>Origins:</Form.Label>
                        <Form.Select onChange={(e) => updateOrigin(e.target.value)}>
                        <option value="">Select origin</option>
                        {origins && origins.map((o, index) => {
                            return (
                                <React.Fragment key={`origin` + index}>
                                    <option value={o.origin} key={`opt_origin` + index}>{o.origin}</option>
                                </React.Fragment>
                            )
                        })}
                        </Form.Select>
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="exampleForm.ControlInputPosition">
                        <Form.Label>Position:</Form.Label>
                        <Form.Select defaultValue={editDataPositionWeb} onChange={(e) => setTypeWeb(e.target.value)}>
                        <option value="">Select position</option>
                        {displayOriginItems && displayOriginItems.map((item, index) => {
                            return (
                                <React.Fragment key={`poscx` + index}>
                                <option value={item} key={`opt_item` + index}>{item}</option>
                                </React.Fragment>
                            )
                        })}
                        </Form.Select>
                    </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                <Button variant="secondary" onClick={handleCloseWeb}>
                    Close
                </Button>
                <Button variant="success" onClick={addWebAds}>
                    Save
                </Button>
                </Modal.Footer>
            </Modal>

            <Container>

                {isLoading && <LoadingSpinner asOverlay />}


                {!isLoading && !accesed ? (
                    <>
                <div className="adspanel-login">

                <center> <img src={`${process.env.PUBLIC_URL}/images/logo.png`}  style={{ height: '50px' }} alt="small logo" /> </center>

                <h3>Panel</h3>

                <hr />     

                <Form onSubmit={submitForm}>
                <Form.Group className="mb-3" controlId="ControlInputPassword">
                    <Form.Label>Password</Form.Label>
                    <Form.Control onChange={(e) => setPassword(e.target.value)} type="password" placeholder="Password" />
                </Form.Group>    

                <Button variant="dark" type="submit"> <i className="fa-solid fa-right-long"></i> </Button>  
                </Form>              
                </div>               
                </>
                ) : (
                 <>
                 <div className="adspanel-private">
                    <Button variant="dark" className="float-end rounded-0" onClick={handleShowCustom}> Add Custom </Button>
                    <Button variant="dark" className="float-end rounded-0" onClick={handleShowIframe}> Add Iframe </Button>
                    <Button variant="dark" className="float-end rounded-0" onClick={handleShow}> Add Ad </Button>
                    <Button variant="success" className="float-end rounded-0" onClick={handleShowWeb}> Add Webs Ad </Button>
                    <Button variant="danger" className="float-end rounded-0" onClick={handleShowSettings}> Settings </Button>

                    <h1>Panel @ ONENIGHTCONFIDENTIAL</h1>

                    <hr />

                    <Tabs defaultActiveKey="adspanel" id="uncontrolled-tab-example" className="mb-3">
                    <Tab eventKey="adspanel" title="Advertisements">
                        {ads && ads.map((ad, index) => {
                            return (
                                <React.Fragment key={`ad` + index}>
                                    <div className="ads-block">
                                        <Row>
                                        <Col sm={1} className="text-center">
                                            {ad.image && (
                                                <>
                                                <img src={ad.image} className="img-fluid" style={{ maxHeight: "75px" }} alt={ad.id} />
                                                </>
                                            )}
                                        </Col>
                                        <Col sm={10}>
                                        <label>Direct url:</label> <a href={ad.image} target="_blank" rel="noreferrer">{ad.image}</a>
                                        <div className="space5px"></div>

                                        <label>Link:</label> <a href={ad.redirection} target="_blank" rel="noreferrer">{ad.redirection}</a>
                                        <div className="space5px"></div>

                                        <label>Aff link:</label> {ad.redirection_aff ? (
                                            <>
                                                <a href={ad.redirection_aff} target="_blank" rel="noreferrer">{ad.redirection_aff}</a>
                                            </>
                                        ) : (
                                            <>
                                                <span style={{ color: 'gray'}}> / </span>
                                            </>
                                        )}

                                        {ad.split === true ? (
                                            <>
                                            <small style={{ color: 'green' }} title="Split system is active!"> <i className="fa-regular fa-circle-dot"></i> </small>
                                            </>
                                        ) : (
                                            <>
                                            <small style={{ color: 'red' }} title="Split system is inactive!"> <i className="fa-regular fa-circle-dot"></i> </small>                                            
                                            </>
                                        )}

                                        <div className="space5px"></div>

                                        <label>Age link:</label> {ad.agelink ? (
                                            <>
                                                <a href={ad.agelink} target="_blank" rel="noreferrer">{ad.agelink}</a>
                                            </>
                                        ) : (
                                            <>
                                                <span style={{ color: 'gray'}}> / </span>
                                            </>
                                        )}

                                        {ad.agesystem === true ? (
                                            <>
                                            <small style={{ color: 'green' }} title="Age system is active!"> <i className="fa-regular fa-circle-dot"></i> </small>
                                            </>
                                        ) : (
                                            <>
                                            <small style={{ color: 'red' }} title="Age system is inactive!"> <i className="fa-regular fa-circle-dot"></i> </small>                                            
                                            </>
                                        )}
                                        
                                        <div className="space5px"></div>
                                        
                                        <label>Email link:</label> 
                                            {ad.emailsystem ? (
                                                <>
                                                {ad.emaillink && (
                                                    <>
                                                        <a href={ad.emaillink} target="_blank" rel="noreferrer">{ad.emaillink}</a>
                                                    </>
                                                )}

                                                &nbsp;
                                                &nbsp;
                                                
                                                {ad.emaillinkolder && (
                                                    <>
                                                        <a href={ad.emaillinkolder} target="_blank" rel="noreferrer">{ad.emaillinkolder}</a>
                                                    </>
                                                )}
                                            </>
                                        ) : (
                                            <>
                                                <span style={{ color: 'gray'}}> / </span>
                                            </>
                                        )}

                                        <div className='space5px'></div>

                                        <label>Position:</label> {getPosition(ad.type)}
                                        </Col>
                                        <Col sm={1} className="text-center">
                                            <Button variant="danger" size='sm' onClick={() => deleteAd(ad.id)}> <i className="fa-solid fa-ban"></i> </Button>
                                            <Button variant="warning" size='sm' onClick={() => editAd(ad.id)} className="m-2"> <i className="fa-solid fa-pencil"></i> </Button>
                                        </Col>
                                        </Row>
                                    </div>
                                </React.Fragment>
                            )
                        })}
                    </Tab>
                    <Tab eventKey="iframepanel" title="Iframes">
                       {iframes && iframes.map((iframe, index) => {
                            return (
                                <React.Fragment key={`iframe` + index}>
                                    <div className="ads-block">
                                        <Row>
                                            <Col sm={1}>
                                            <Form.Check 
                                                type="switch"
                                                id="iframe-status"
                                                className="form-check"
                                                onChange={(e) => {
                                                    const obj = {
                                                        "iframeid": iframe.id,
                                                        "display": e.target.checked
                                                    }

                                                    updateIframeToggle(obj);
                                                    
                                                }}
                                                checked={iframe?.display}
                                            />
                                            </Col>
                                            <Col sm={10}>
                                            <label>Direct url:</label> <a href={iframe.url} target="_blank" rel="noreferrer">{iframe.url}</a>
                                            <div className="space5px"></div>

                                            <label>Title:</label> {iframe.title}
                                            <div className="space5px"></div>

                                            <label>Position:</label> {getPositionIframe(iframe.type)}                                             
                                            </Col>
                                            <Col sm={1} className="text-center">
                                                <Button variant="danger" size='sm' onClick={() => deleteIframe(iframe.id)}> <i className="fa-solid fa-ban"></i> </Button>
                                                <Button variant="warning" size='sm' onClick={() => editIframe(iframe.id)} className="m-2"> <i className="fa-solid fa-pencil"></i> </Button>
                                            </Col>
                                        </Row>
                                    </div>
                                </React.Fragment>
                            )
                       })}
                    </Tab>
                    <Tab eventKey="custompanel" title="Custom">
                        {custom && custom.map((c, index) => {
                            return (
                                <React.Fragment key={'custom' + index}>
                                    <div className="ads-block">
                                        <Row>
                                        <Col sm={1} className="text-center">
                                            <img src={c.image} className="img-fluid" style={{ maxHeight: "75px" }} alt={c.id} />
                                        </Col>
                                        <Col sm={10}>
                                        <label>Direct url:</label> <a href={c.url} target="_blank" rel="noreferrer">{c.image}</a>
                                        <div className="space5px"></div>

                                        <label>Link:</label> <a href={c.redirection} target="_blank" rel="noreferrer">{c.redirection}</a>
                                        <div className="space5px"></div>

                                        <label>Aff link:</label> {c.redirection_aff ? (
                                            <>
                                                <a href={c.redirection_aff} target="_blank" rel="noreferrer">{c.redirection_aff}</a>
                                            </>
                                        ) : (
                                            <>
                                                <span style={{ color: 'gray'}}> / </span>
                                            </>
                                        )}

                                        {c.split === true ? (
                                            <>
                                            <small style={{ color: 'green' }} title="Split system is active!"> <i className="fa-regular fa-circle-dot"></i> </small>
                                            </>
                                        ) : (
                                            <>
                                            <small style={{ color: 'red' }} title="Split system is inactive!"> <i className="fa-regular fa-circle-dot"></i> </small>                                            
                                            </>
                                        )}
                                        
                                        <div className="space5px"></div>

                                        <label>Position:</label> {getPositionCustom(c.type)}
                                        </Col>
                                        <Col sm={1} className="text-center">
                                            <Button variant="danger" size='sm' onClick={() => deleteCustom(c.id)}> <i className="fa-solid fa-ban"></i> </Button>
                                            <Button variant="warning" size='sm' onClick={() => editCustom(c.id)} className="m-2"> <i className="fa-solid fa-pencil"></i> </Button>
                                        </Col>
                                        </Row>
                                    </div>
                                </React.Fragment>
                            )
                        })}
                    </Tab>

                    <Tab eventKey="tagspanel" title="Tags">
                        <Button variant="dark" size="sm" onClick={handleShowTags}> Create tag </Button>

                        {tags && tags.map((t, index) => {
                            return (
                                <React.Fragment key={`t` + index}>
                                    <div className="ads-block">
                                        <Row>
                                            <Col sm={1}>
                                                <Form.Check 
                                                    type="switch"
                                                    id="tag-status"
                                                    className="form-check"
                                                    onChange={(e) => {
                                                        const obj = {
                                                            "tagname": t.name,
                                                            "active": e.target.checked
                                                        }

                                                        updateTagsToggle(obj);
                                                        
                                                    }}
                                                    checked={t.active}
                                                />
                                            </Col>
                                            <Col sm={10}>
                                            <label>Tag name:</label> {t.name}
                                            <div className="space5px"></div>

                                            <label>Tagged members:</label> {t.members.length}
                                            <div className="space5px"></div>  
                                            </Col>
                                            <Col sm={1}>
                                            <Button variant="danger" size='sm' onClick={() => deleteTag(t.name)}> <i className="fa-solid fa-ban"></i> </Button> <br />
                                            <Button variant="warning" size='sm' onClick={() => editTag(t.name)} className="mt-1"> <i className="fa-solid fa-pencil"></i> </Button>
                                            </Col>
                                        </Row>
                                    </div>
                                </React.Fragment>
                            )
                        })}
                    </Tab>

                    <Tab eventKey="webadspanel" title="Webs Advertisements">
                    {webAds && webAds.map((web, index) => {
                            return (
                                <React.Fragment key={`web` + index}>
                                    <div className="ads-block">
                                        <Row>
                                        <Col sm={1} className="text-center">
                                            <img src={web.image} className="img-fluid" style={{ maxHeight: "75px" }} alt={web.id} />
                                        </Col>
                                        <Col sm={10}>
                                        <label>Direct url:</label> <a href={web.image} target="_blank" rel="noreferrer">{web.image}</a>
                                        <div className="space5px"></div>

                                        <label>Link:</label> <a href={web.redirection} target="_blank" rel="noreferrer">{web.redirection}</a>
                                        <div className="space5px"></div>

                                        <label>Origin:</label> <a href={web.origin} target="_blank" rel="noreferrer">{web.origin}</a>
                                        <div className="space5px"></div>

                                        <label>Position:</label> {web.type}
                                        </Col>
                                        <Col sm={1} className="text-center">
                                            <Button variant="danger" size='sm' onClick={() => deleteWebAd(web.id)}> <i className="fa-solid fa-ban"></i> </Button>
                                            <Button variant="warning" size='sm' onClick={() => editWebAd(web.id)} className="m-2"> <i className="fa-solid fa-pencil"></i> </Button>
                                        </Col>
                                        </Row>
                                    </div>
                                </React.Fragment>
                            )
                        })}                        
                    </Tab>
                    </Tabs>
                 </div>
                 </>
                )}

            </Container>
        </React.Fragment>
    )
}

export default Panel;